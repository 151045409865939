import { useMutation } from "@apollo/client";
import {
  ChartSoundFade,
  EditorUpdateFadeValueDocument,
} from "../../../../../../__generated__/gql/graphql";
import { Slider } from "@blueprintjs/core";
import { useCallback, useEffect, useState } from "react";

export const FADE_SLIDER_MAX = 100.0;

export type FadeSliderProps = {
  fade: ChartSoundFade;
};

export function FadeSlider({ fade }: FadeSliderProps) {
  const [value, setValue] = useState(fade.value);

  const [updateValue] = useMutation(EditorUpdateFadeValueDocument);

  useEffect(() => {
    setValue(fade.value);
  }, [fade.value]);

  const handleChange = useCallback(
    (value: number) => {
      setValue(value);
    },
    [setValue],
  );

  const handleRelease = useCallback(
    (value: number) => {
      updateValue({
        variables: {
          id: fade.id,
          value,
        },
      });
    },
    [updateValue, fade.id],
  );

  return (
    <Slider
      vertical={true}
      labelRenderer={false}
      max={FADE_SLIDER_MAX}
      min={0.0}
      value={value}
      onChange={handleChange}
      onRelease={handleRelease}
    />
  );
}
