import { styled } from "styled-components";
import {
  ChartSoundSource,
  UpdateSoundShapeRadial2DDocument,
} from "../../../../../__generated__/gql/graphql";
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useCanvasKit } from "../../../contexts/CanvasKitContext";
import {
  SelectionType,
  useSelection,
} from "../../../contexts/SelectionContext";
import { Tool, useTool } from "../../../contexts/ToolContext";
import { Draggable } from "./Draggable";
import { useAreaLocalCoords } from "../../../hooks/useAreaLocalCoords";
import { useArea } from "../../../contexts/AreaContext";
import { useAreaGlobalCoords } from "../../../hooks/useAreaGlobalCoords";
import { useMutation } from "@apollo/client";
import { bakeRadial2D } from "../../bake/shape/radial2d";
import { BakeMode } from "../../bake/types";
import { sortBy } from "lodash";

const DrawCanvas = styled.canvas`
  position: absolute;
`;

export type SoundSourceMarkerProps = {
  soundSource: ChartSoundSource;
};

export default function SoundSourceMarker({
  soundSource,
}: SoundSourceMarkerProps) {
  const { area } = useArea();
  const [updateRadius] = useMutation(UpdateSoundShapeRadial2DDocument);

  const { selectionType, selectionId, setSelection } = useSelection();
  const selected =
    selectionType === SelectionType.ChartSoundSource &&
    selectionId === soundSource.id;

  const { tool } = useTool();
  const [hover, setHover] = useState(false);

  // FIXME detect shape
  const shape = soundSource.shape;

  // FIXME move origin to sound
  const { x: storedX, y: storedY } = useAreaLocalCoords(
    soundSource.origin.globalLongitude,
    soundSource.origin.globalLatitude,
  );

  const [{ x: draggableX, y: draggableY }, setDraggablePosition] = useState({
    x: storedX,
    y: storedY,
  });

  useEffect(() => {
    setDraggablePosition({ x: storedX, y: storedY });
  }, [storedX, storedY]);

  const {
    globalLongitude: draggableGlobalLongitude,
    globalLatitude: draggableGlobalLatitude,
  } = useAreaGlobalCoords(draggableX, draggableY);

  const handleMouseOver = () => {
    setHover(true);
  };

  const handleMouseOut = () => {
    setHover(false);
  };

  const handleClick = useCallback(() => {
    if (tool === Tool.Select) {
      setSelection(SelectionType.ChartSoundSource, soundSource.id);
    }
  }, [tool, soundSource, setSelection]);

  const handleDragEnd = () => {
    updateRadius({
      variables: {
        id: soundSource.id,
        radius: shape.radius,
        globalLongitude: draggableGlobalLongitude,
        globalLatitude: draggableGlobalLatitude,
      },
    });
  };

  const canvasRef = useRef<HTMLCanvasElement>();
  const canvasKit = useCanvasKit();

  const bakeMode = selected
    ? BakeMode.EditorSelected
    : hover
      ? BakeMode.EditorHover
      : BakeMode.EditorRegular;

  const { displayWidth, displayHeight, drawCallback } = bakeRadial2D(
    {
      pixelToMetricRatio: area.localToRealRatio,
    },
    soundSource.shape,
    area.groups.find((group) => group.id === soundSource.groupId)?.color! ||
      "#000000",
    sortBy(soundSource.fades, (f) => f.position).map((f) => f.value),
  );

  useLayoutEffect(() => {
    if (!canvasRef.current) return;
    const surface = canvasKit.MakeSWCanvasSurface(canvasRef.current)!;
    surface.drawOnce((canvas) => drawCallback(canvasKit, canvas, bakeMode));
  });

  return (
    <Draggable
      x={draggableX}
      y={draggableY}
      width={displayWidth}
      height={displayHeight}
      onDragPositionChange={(newPosition) => setDraggablePosition(newPosition)}
      onDragEnd={handleDragEnd}
    >
      <DrawCanvas
        width={displayWidth}
        height={displayHeight}
        ref={canvasRef as MutableRefObject<HTMLCanvasElement>}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={handleClick}
      />
    </Draggable>
  );
}
