const CACHE = new Map<string, string>();

export interface Attachment {
  __typename?: string;
  id: string;
}

/**
 * This hook is intended to cache URL of the attachment.
 * 
 * The idea is that data from the API can be reloaded frequently, and in
 * such case the temporary URLs pointing to attachments will change upon
 * each refresh as usually they contain parameter based on the expiration
 * date of the temporary URL. Using this URL directly will cause many 
 * unnecessary re-renders and re-fetches.
 * 
 * This hook is intended to solve this problem by keeping track of the
 * Etag of the attachment and refreshing the URL only if the Etag changes. 
 * 
 * It uses quite primitive mechanism with global variable acting as 
 * a cache as attempts to base this on hooks such as useMemo etc. failed.
 * They generally worked fine, but if useAttachmentUrl was used within
 * react-three-fiber canvas they were not memoizing variables.
 * 
 * TODO:
 * - unused cache key eviction
 * - handle URL expiry
 */
export function useAttachmentUrl(value: Attachment, prefix: string) {
  if(!(`${prefix}Etag` in value)) {
    throw new Error(`useAttachmentUrl: Missing Etag on value ${JSON.stringify(value)}`);
  }
  if(!(`${prefix}FetchUrl` in value)) {
    throw new Error(`useAttachmentUrl: Missing FetchUrl on value ${JSON.stringify(value)}`);
  }

  const etag: string = (value as any)[`${prefix}Etag`];
  const fetchUrl: string = (value as any)[`${prefix}FetchUrl`];

  if(!value.__typename || !etag || !fetchUrl) return null;

  const cacheKey = `${value.__typename}-${value.id}-${etag}`;
  if(CACHE.has(cacheKey)) {
    return CACHE.get(cacheKey);
  }
  CACHE.set(cacheKey, fetchUrl);
  return fetchUrl;
}