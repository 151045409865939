import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useRef } from "react";
import { Vector3 } from "three";
import { useArea } from "../../contexts/AreaContext";

// See https://codesandbox.io/p/sandbox/r3f-wasd-controls-wft0n?file=%2Fsrc%2FWasdControls.js%3A20%2C1-45%2C1

function useCodes() {
  const codes = useRef(new Set())
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => codes.current.add(e.code)
    const onKeyUp = (e: KeyboardEvent) => codes.current.delete(e.code)
    window.addEventListener('keydown', onKeyDown)
    window.addEventListener('keyup', onKeyUp)
    return () => {
      window.removeEventListener('keydown', onKeyDown)
      window.removeEventListener('keyup', onKeyUp)
    }
  }, []);
  return codes
}

const SPEED_WALK_FACTOR = 30;
const SPEED_RUN_FACTOR = 200;

const vec = new Vector3()

// Rotation logic from three/examples/jsm/controls/PointerLockControls.js
export default function WASDControls() {
  const { camera } = useThree()
  const { area } = useArea();

  const code = useCodes()
  const moveForward = (distance: number) => {
    vec.setFromMatrixColumn(camera.matrix, 0)
    vec.crossVectors(camera.up, vec)
    camera.position.addScaledVector(vec, distance)
  }
  const moveRight = (distance: number) => {
    vec.setFromMatrixColumn(camera.matrix, 0)
    camera.position.addScaledVector(vec, distance)
  }
  useFrame((_, delta) => {
    const speed = code.current.has('ShiftLeft') ? SPEED_RUN_FACTOR / area.localToRealRatio : SPEED_WALK_FACTOR / area.localToRealRatio
    if (code.current.has('KeyW')) moveForward(delta * speed)
    if (code.current.has('KeyA')) moveRight(-delta * speed)
    if (code.current.has('KeyS')) moveForward(-delta * speed)
    if (code.current.has('KeyD')) moveRight(delta * speed)
  })
  return null
}
