import { Button } from "@blueprintjs/core";
import { useNavigate } from "react-router";
import { styled } from "styled-components";

const Container = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
`;

export default function CloseButton() {
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    navigate("/");
  };

  return (
    <Container>
      <Button icon="cross" onClick={handleClick} />
    </Container>
  );
}
