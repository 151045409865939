import EditFormField from "./EditFormField";
import { EditFormSchema } from "./useEditForm";

export type EditFormProps = {
  formSchema: EditFormSchema;
};

export default function EditForm({ formSchema }: EditFormProps) {
  const { formFields, setFormValue } = formSchema;
  return (
    <>
      {formFields
        .filter((f) => f.name !== "id")
        .map((formField) => (
          <EditFormField
            key={formField.name}
            formField={formField}
            onChange={(value: any) => setFormValue(formField.name, value)}
            value={formField.value}
          />
        ))}
    </>
  );
}
