import { Socket } from "phoenix";
import { ReactNode, createContext, useContext, useEffect, useState } from "react";

export type UserSocketContextState = {
  userSocket: Socket | undefined;
};

const UserSocketContext = createContext<UserSocketContextState>(undefined as any);

export function useUserSocket() {
  return useContext(UserSocketContext);
}

export type UserSocketProviderProps = {
  children: ReactNode;
  accessToken: string;
}

const SOCKET_URL = `${process.env.REACT_APP_UNDERTONE_API_URL!.replace('http', 'ws')}/api/v1/user/socket`;

export function UserSocketProvider({ children, accessToken }: UserSocketProviderProps) {
  const [userSocket, setUserSocket] = useState<Socket | undefined>();

  console.debug('[UserSocketProvider] Render');

  useEffect(() => {
    console.debug('[UserSocket] Connecting');
    const userSocket = new Socket(SOCKET_URL, { params: { access_token: accessToken }});

    userSocket.onOpen(() => {
      console.debug('[UserSocket] Connected');
    });
    userSocket.onClose((event) => {
      console.debug('[UserSocket] Disconnected', event);
    });
    userSocket.onError((error) => {
      console.warn('[UserSocket] Error', error);
    });

    userSocket.connect();
    setUserSocket(userSocket);

    // FIXME causes errors on rerenders
    // return () => {
    //  console.debug('[UserSocket] Disconnecting');
    //  userSocket.disconnect();
    // }
  }, [setUserSocket, accessToken]);

  return <UserSocketContext.Provider value={{ userSocket }}>{children}</UserSocketContext.Provider>;
}