import styled from "styled-components";
import { Button, InputGroup } from "@blueprintjs/core";
import { useCallback, useMemo, useState } from "react";

const Container = styled.div`
  display: flex;
`;

export type TreeEditableLabelProps = {
  defaultValue: string;
  onSave: (newName: string) => void;
  onCancel: () => void;
};

export function TreeEditableLabel({
  defaultValue,
  onSave,
  onCancel,
}: TreeEditableLabelProps) {
  const [value, setValue] = useState(defaultValue);

  const handleCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleSave = useCallback(() => {
    onSave(value);
  }, [onSave, value]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    },
    [setValue],
  );

  const isDisabled = useMemo(() => value.trim().length === 0, [value]);

  const handleInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        handleSave();
      } else if (e.key === "Escape") {
        handleCancel();
      }
    },
    [handleSave, handleCancel],
  );

  return (
    <Container>
      <InputGroup
        defaultValue={defaultValue}
        autoFocus
        onChange={handleChange}
        fill
        onKeyDown={handleInputKeyDown}
      />
      <Button icon="edit" onClick={handleSave} disabled={isDisabled} />
      <Button icon="cross" onClick={handleCancel} />
    </Container>
  );
}
