import { styled } from "styled-components";
import { useArea } from "../../../contexts/AreaContext";
import AnnotationMarker from "./AnnotationMarker";

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export default function AnnotationOverlay() {
  const { area } = useArea();

  return (
    <Container>
      {area?.annotations?.map((annotation) => (
        <AnnotationMarker key={annotation.id} annotation={annotation} />
      ))}
    </Container>
  );
}
