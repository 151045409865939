import styled from "styled-components";
import { DETAILS_SIDEBAR_WIDTH } from "../DetailsSidebar";
import { Button } from "@blueprintjs/core";
import { useArea } from "../../../contexts/AreaContext";
import { useMutation } from "@apollo/client";
import {
  ChartSoundGroup,
  EditorCreateGroupDocument,
  EditorUpdateGroupVisibleDocument,
} from "../../../../../__generated__/gql/graphql";
import { useCallback, useState } from "react";
import {
  SelectionType,
  useSelection,
} from "../../../contexts/SelectionContext";
import { MoveDialog } from "./MoveDialog";
import { ColorDialog } from "./ColorDialog";
import { ContentTree } from "./ContentTree";

export const TREE_SIDEBAR_WIDTH = 400;

const Container = styled.div`
  position: absolute;
  right: ${DETAILS_SIDEBAR_WIDTH}px;
  top: 0;
  bottom: 0;
  width: ${TREE_SIDEBAR_WIDTH}px;
  background: #fff;
  border-left: 1px solid #e0e0e0;
`;

const DEFAULT_GROUP_NAME_PREFIX = "Group ";
const MAX_GROUP_NAME_NUMBER = 100000;

// FIXME that probably should be done server-side via custom mutation
// that ensures that we don't have collisions
function findNextGroupName(groups: ChartSoundGroup[]) {
  const existingNames = groups.map((group) => group.name);
  for (let i = 1; i < MAX_GROUP_NAME_NUMBER; i++) {
    const name = `${DEFAULT_GROUP_NAME_PREFIX}${i}`;
    if (!existingNames.includes(name)) {
      return name;
    }
  }
  throw new Error("Unable to determine next free group name");
}

export default function TreeSidebar() {
  const { area } = useArea();
  const [createGroup] = useMutation(EditorCreateGroupDocument);

  // Selection

  const { selectionId, selectionType, setSelection } = useSelection();

  // Creation of new group

  const handleCreateGroup = () => {
    const name = findNextGroupName(area.groups);
    createGroup({
      variables: {
        areaId: area.id,
        name,
      },
    });
  };

  // Visibility

  const [updateGroupVisible] = useMutation(EditorUpdateGroupVisibleDocument);

  const handleVisible = useCallback(() => {
    if (selectionType !== SelectionType.ChartSoundGroup) {
      return;
    }
    area.groups.forEach((group) => {
      if (group.id === selectionId) {
        updateGroupVisible({
          variables: {
            id: selectionId,
            visible: !group.visible,
          },
        });
        return;
      }
    });
  }, [updateGroupVisible, selectionId, selectionType, area.groups]);

  // Movement

  const [moveDialogOpened, setMoveDialogOpened] = useState(false);

  const handleCloseMoveDialog = useCallback(() => {
    setMoveDialogOpened(false);
  }, [setMoveDialogOpened]);

  const handleMove = useCallback(() => {
    setMoveDialogOpened(true);
  }, [setMoveDialogOpened]);

  // Color selection

  const [colorDialogOpened, setColorDialogOpened] = useState(false);

  const handleCloseColorDialog = useCallback(() => {
    setColorDialogOpened(false);
  }, [setColorDialogOpened]);

  const handleColor = useCallback(() => {
    setColorDialogOpened(true);
  }, [setColorDialogOpened]);

  // Rendering

  return (
    <>
      <MoveDialog
        isOpen={moveDialogOpened}
        onClose={handleCloseMoveDialog}
        subject={{ selectionId, selectionType }}
      />
      {selectionType === SelectionType.ChartSoundGroup && (
        <ColorDialog
          isOpen={colorDialogOpened}
          onClose={handleCloseColorDialog}
          subject={{ selectionId, selectionType }}
        />
      )}
      <Container>
        <ContentTree
          selectionType={selectionType}
          selectionId={selectionId}
          onSelectionChange={setSelection}
          enableSources={true}
          editable={true}
        />
        <Button
          icon="folder-new"
          onClick={handleCreateGroup}
          title="Create group"
        />
        <Button
          icon="move"
          onClick={handleMove}
          title="Move selected item"
          disabled={
            !(
              selectionType === SelectionType.ChartSoundGroup ||
              selectionType === SelectionType.ChartSoundSource
            )
          }
        />
        <Button
          icon="color-fill"
          onClick={handleColor}
          title="Select color of the selected group"
          disabled={selectionType !== SelectionType.ChartSoundGroup}
        />
        <Button
          icon="eye-open"
          onClick={handleVisible}
          title="Toggle visibility of the selected group"
          disabled={selectionType !== SelectionType.ChartSoundGroup}
        />
      </Container>
    </>
  );
}
