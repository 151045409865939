import { useState } from "react";
import { SelectionProvider } from "../contexts/SelectionContext";
import { ToolProvider } from "../contexts/ToolContext";
import DetailsSidebar from "./details/DetailsSidebar";
import Preview2DContainer from "./preview2d/Preview2DContainer";
import Preview3DContainer from "./preview3d/Preview3DContainer";
import ToolboxSidebar from "./toolbox/ToolboxSidebar";
import TreeSidebar from "./details/TreeSidebar";

export type WorkspacePreviewMode = "2d" | "3d";

export default function Workspace() {
  const [previewMode, setPreviewMode] = useState<WorkspacePreviewMode>("2d");

  return (
    <ToolProvider>
      <SelectionProvider>
        <ToolboxSidebar
          previewMode={previewMode}
          changePreviewMode={(newPreviewMode) => setPreviewMode(newPreviewMode)}
        />
        {previewMode === "2d" ? <Preview2DContainer /> : <Preview3DContainer />}
        <TreeSidebar />
        <DetailsSidebar />
      </SelectionProvider>
    </ToolProvider>
  );
}
