import { styled } from "styled-components";
import { TOOLBOX_SIDEBAR_WIDTH } from "../toolbox/ToolboxSidebar";
import { DETAILS_SIDEBAR_WIDTH } from "../details/DetailsSidebar";

import { Canvas } from "@react-three/fiber";
import { PointerLockControls } from "@react-three/drei";
import { useArea } from "../../contexts/AreaContext";

import WASDControls from "./WASDControls";
import BackgroundMesh from "./BackgroundMesh";
import SoundSourceMesh from "./SoundSourceMesh";
import DeviceForwarder from "./DeviceForwarder";
import { TREE_SIDEBAR_WIDTH } from "../details/TreeSidebar";

const Container = styled.div`
  position: absolute;
  left: ${TOOLBOX_SIDEBAR_WIDTH}px;
  right: ${DETAILS_SIDEBAR_WIDTH + TREE_SIDEBAR_WIDTH}px;
  top: 0;
  bottom: 0;
  overflow: hidden;
  background: #fff;
  user-select: none;
`;

export default function Preview3DContainer() {
  const { area, background } = useArea();

  return (
    <Container>
      <Canvas
        camera={{
          fov: 90,
          position: [area.localWidth / 2.0, 10, area.localHeight / 2.0],
        }}
      >
        <ambientLight intensity={0.1} />
        <directionalLight color="red" position={[0, 0, 5]} />
        <BackgroundMesh background={background} />

        {area.sources
          .filter((source) => !source.ambient)
          .map((source) => (
            <SoundSourceMesh key={source.id} source={source} />
          ))}
        <PointerLockControls />
        <WASDControls />
        <DeviceForwarder />
        <axesHelper args={[10000]} />
      </Canvas>
    </Container>
  );
}
