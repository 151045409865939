import { useAuth0 } from "@auth0/auth0-react";
import { Alignment, Button, Navbar } from "@blueprintjs/core";
import { Outlet, useNavigate } from "react-router";
import { styled } from "styled-components";
import SoundFileUpload from "./features/assets/SoundFileUpload";
import SoundFileIndex from "./features/assets/SoundFileIndex";
import AreaIndex from "./features/areas/AreaIndex";
import DeviceIndex from "./features/devices/DeviceIndex";
import UserIndex from "./features/users/UserIndex";

const UserInfo = styled.span`
  color: #999;
`;

const ADMIN_ROUTES = [
  {
    path: "devices",
    element: <DeviceIndex />,
  },
  {
    path: "areas",
    element: <AreaIndex />,
  },
  {
    path: "files",
    element: <SoundFileIndex />,
  },
  {
    path: "users",
    element: <UserIndex />,
  },
  {
    path: "files/upload",
    element: <SoundFileUpload />,
  },
];

export default function AdminIndex() {
  const navigate = useNavigate();
  const { user, logout } = useAuth0();

  return (
    <>
      <Navbar>
        <Navbar.Group align={Alignment.LEFT}>
          <Navbar.Heading>Undertone Cloud</Navbar.Heading>
          <Navbar.Divider />
          <Button
            minimal
            icon="headset"
            text="Devices"
            onClick={() => navigate("devices")}
          />
          <Button
            minimal
            icon="map"
            text="Areas"
            onClick={() => navigate("areas")}
          />
          <Button
            minimal
            icon="document"
            text="Files"
            onClick={() => navigate("files")}
          />
          <Button
            minimal
            icon="user"
            text="Users"
            onClick={() => navigate("users")}
          />
        </Navbar.Group>

        <Navbar.Group align={Alignment.RIGHT}>
          <UserInfo>{user?.email}</UserInfo>
          <Button
            minimal
            icon="log-out"
            text="Log out"
            onClick={() => logout()}
          />
        </Navbar.Group>
      </Navbar>
      <div>
        <Outlet />
      </div>
    </>
  );
}

export { ADMIN_ROUTES };
