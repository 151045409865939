import { RecordTable } from "../../components/RecordTable";
import {
  AdminReadAreasDocument,
  type ChartArea,
  AdminDeleteAreaDocument,
  AdminCreateAreaDocument,
  AdminUpdateAreaDocument,
} from "../../../../__generated__/gql/graphql";
import { Button } from "@blueprintjs/core";
import { useNavigate } from "react-router";

export default function AreaIndex() {
  const navigate = useNavigate();
  return RecordTable<ChartArea>({
    query: AdminReadAreasDocument,
    createMutation: AdminCreateAreaDocument,
    dataKey: "chartAreas",
    deleteMutation: AdminDeleteAreaDocument,
    updateMutation: AdminUpdateAreaDocument,
    recordNameKey: "name",
    model: "ChartArea",
    columns: [
      { name: "id" },
      { name: "name" },
      { name: "status" },
      {
        name: "edit",
        renderer: (record) => (
          <Button
            small
            icon="edit"
            text="Edit"
            onClick={() => navigate(`/editor/${record.id}`)}
          />
        ),
      },
    ],
  });
}
