import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";

export enum SelectionType {
  None = "",
  ChartAnnotation = "ChartAnnotation",
  ChartSoundGroup = "ChartSoundGroup",
  ChartSoundSource = "ChartSoundSource",
  ClientDevice = "ClientDevice",
}

export type SelectionSubject = {
  selectionType: SelectionType;
  selectionId: string;
};

export type SelectionState = SelectionSubject & {
  setSelection: (
    newSelectionType: SelectionType,
    newSelectionId: string,
  ) => void;
};

const SelectionContext = createContext<SelectionState>({
  selectionType: SelectionType.None,
  selectionId: "",
  setSelection: () => {},
});

export function useSelectionState() {
  const [selectionType, setSelectionType] = useState(SelectionType.None);
  const [selectionId, setSelectionId] = useState("");

  const setSelection = useCallback(
    (newSelectionType: SelectionType, newSelectionId: string) => {
      setSelectionType(newSelectionType);
      setSelectionId(newSelectionId);
    },
    [setSelectionId, setSelectionType],
  );

  return { selectionType, selectionId, setSelection };
}

export function useSelection() {
  return useContext(SelectionContext);
}

export type SelectionProviderProps = {
  children: ReactNode;
};

export function SelectionProvider({ children }: SelectionProviderProps) {
  const value = useSelectionState();

  return (
    <SelectionContext.Provider value={value}>
      {children}
    </SelectionContext.Provider>
  );
}
