import { useMemo } from "react";
import { useArea } from "../contexts/AreaContext";

export function useAreaGlobalCoords(x: number, y: number) {
  const { area, sm } = useArea();

  const globalCoords = useMemo(() => {
    const ll = sm.ll(
      [area.localOffsetX + x, area.localOffsetY + y],
      area.backgroundZoom,
    );

    return {
      globalLongitude: ll[0],
      globalLatitude: ll[1],
    };
  }, [sm, area.backgroundZoom, area.localOffsetX, area.localOffsetY, x, y]);

  return globalCoords;
}
