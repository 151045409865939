import { RecordTable } from "../../components/RecordTable";
import {
  AdminReadAccountsDocument,
  type UserAccount,
  AdminDeleteAccountDocument,
  AdminCreateAccountDocument,
  AdminUpdateAccountDocument,
} from "../../../../__generated__/gql/graphql";
import DateTimeCell from "../../components/RecordTable/cell/DateTimeCell";

export default function UserIndex() {
  return RecordTable<UserAccount>({
    createMutation: AdminCreateAccountDocument,
    updateMutation: AdminUpdateAccountDocument,
    deleteMutation: AdminDeleteAccountDocument,
    query: AdminReadAccountsDocument,
    dataKey: "userAccounts",
    columns: [
      { name: "id" },
      { name: "email" },
      { name: "lastActiveAt", renderer: DateTimeCell },
    ],
    recordNameKey: "email",
    model: "UserAccount",
  });
}
