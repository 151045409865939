import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { createNetworkStatusNotifier } from "react-apollo-network-status";

const apiUrl = process.env.REACT_APP_UNDERTONE_API_URL
  ? process.env.REACT_APP_UNDERTONE_API_URL
  : "https://api.undertone.art";
const fullApiUrl = `${apiUrl}/api/v1/graphql`;

const { link: networkStatusLink, useApolloNetworkStatus } =
  createNetworkStatusNotifier();

// Re-export using slightly different name to avoid confusion
export function useNetworkStatus() {
  return useApolloNetworkStatus();
}

export function createApolloClient(accessToken: string) {
  const httpLink = createHttpLink({
    uri: fullApiUrl,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return new ApolloClient({
    link: networkStatusLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
}
