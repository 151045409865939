import { styled } from "styled-components";
import SoundSourcesPanel from "./panels/SoundSourcesPanel";
import SelectedPanel from "./panels/SelectedPanel";
import DevicesPanel from "./panels/DevicesPanel";

export const DETAILS_SIDEBAR_WIDTH = 400;

const Container = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: ${DETAILS_SIDEBAR_WIDTH}px;
  background: #666;
`;

export default function DetailsSidebar() {
  return (
    <Container>
      <SoundSourcesPanel />
      <DevicesPanel />
      <SelectedPanel />
      {/* <div id="dataGps"></div>
      <div id="dataImu"></div> */}
    </Container>
  );
}
