import { CanvasKit } from "canvaskit-wasm";
import { createContext, useContext, ReactNode } from "react";

// That will be used only if properly initialized, so it's safe
// to force invalid initial value.
const CanvasKitContext = createContext<CanvasKit>({} as any);

export function useCanvasKit() {
  const instance = useContext(CanvasKitContext);
  if (!instance) {
    throw new Error("useCanvasKit was called but CanvasKit is not loaded yet");
  }
  return instance;
}

export type CanvasKitProviderProps = {
  children: ReactNode;
  value: CanvasKit;
};

/**
 * Provides information about currently edited area.
 */
export function CanvasKitProvider({ children, value }: CanvasKitProviderProps) {
  return (
    <CanvasKitContext.Provider value={value}>
      {children}
    </CanvasKitContext.Provider>
  );
}
