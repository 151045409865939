import styled from "styled-components";
import { ChartSoundSource } from "../../../../../../__generated__/gql/graphql";
import { sortBy } from "lodash";
import { FadeSlider } from "./FadeSlider";

const Container = styled.div`
  display: flex;
  margin-bottom: 24px;
  margin-top: 24px;
`;

export type FadeSlidersProps = {
  source: ChartSoundSource;
};

export function FadeSliders({ source }: FadeSlidersProps) {
  return (
    <Container>
      {sortBy(source.fades, (f) => f.position).map((fade) => (
        <FadeSlider key={fade.id} fade={fade} />
      ))}
    </Container>
  );
}
