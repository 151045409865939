import { styled } from "styled-components";
import { useArea } from "../../../contexts/AreaContext";
import SoundSourceMarker from "./SoundSourceMarker";

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export default function SoundSourceOverlay() {
  const { area } = useArea();

  return (
    <Container>
      {area.sources
        .filter((soundSource) => !soundSource.ambient)
        .filter(
          (soundSource) => !soundSource.group || soundSource.group.visible,
        )
        .map((soundSource) => (
          <SoundSourceMarker key={soundSource.id} soundSource={soundSource} />
        ))}
    </Container>
  );
}
