import { RecordTable } from "../../components/RecordTable";
import {
  AdminReadDevicesDocument,
  type ClientDevice,
  AdminDeleteDeviceDocument,
  ChartArea,
  AdminCreateDeviceDocument,
  AdminUpdateDeviceDocument,
} from "../../../../__generated__/gql/graphql";
import DateTimeCell from "../../components/RecordTable/cell/DateTimeCell";

function AreaCell(
  _record: any,
  _column: string,
  value: Pick<ChartArea, "name">,
) {
  return value ? value.name : null;
}

export default function DeviceIndex() {
  return RecordTable<ClientDevice>({
    createMutation: AdminCreateDeviceDocument,
    updateMutation: AdminUpdateDeviceDocument,
    deleteMutation: AdminDeleteDeviceDocument,
    query: AdminReadDevicesDocument,
    dataKey: "clientDevices",
    columns: [
      { name: "id" },
      { name: "hostname" },
      { name: "connectedAt", renderer: DateTimeCell },
      { name: "accessToken" },
      { name: "area", renderer: AreaCell },
    ],
    recordNameKey: "hostname",
    model: "ClientDevice",
  });
}
