import { createHashRouter } from "react-router-dom";
import Root from "./../routes/Root";
import EditorIndex from "../features/editor/EditorIndex";
import AdminIndex, { ADMIN_ROUTES } from "../features/admin/AdminIndex";

const router = createHashRouter([
  {
    path: "/",
    element: <Root />,
  },
  {
    path: "/admin",
    element: <AdminIndex />,
    children: ADMIN_ROUTES,
  },
  {
    path: "/editor/:areaId",
    element: <EditorIndex />,
  },
]);

export default router;
