import { createContext, useContext, ReactNode, useState, useMemo } from "react";
import {
  ChartArea,
  ChartBackground,
  ChartBackgroundStyleEnum,
} from "../../../__generated__/gql/graphql";
import SphericalMercator from "@mapbox/sphericalmercator";

export type AreaContextState = {
  area: ChartArea;
  background: ChartBackground;
  sm: SphericalMercator,
  setBackgroundStyle: (newStyle: ChartBackgroundStyleEnum) => void;
};

const AreaContext = createContext<AreaContextState>(undefined as any);

export function useArea() {
  return useContext(AreaContext);
}

export type AreaProviderProps = {
  children: ReactNode;
  area: ChartArea;
};

const DEFAULT_BACKGROUND_STYLE = ChartBackgroundStyleEnum.Outdoors;

/**
 * Provides information about currently edited area.
 */
export function AreaProvider({ children, area }: AreaProviderProps) {
  const [backgroundStyle, setBackgroundStyle] = useState(
    DEFAULT_BACKGROUND_STYLE,
  );

  const background = useMemo(
    () =>
      area.backgrounds.find(
        (background) => background.style === backgroundStyle,
      ),
    [backgroundStyle, area.backgrounds],
  );

  const sm = useMemo(() => new SphericalMercator({ size: area.backgroundTileSize }), [area.backgroundTileSize]);

  if (!background)
    throw new Error(`Unable to find background with style ${backgroundStyle}`);

  const state = {
    area,
    background,
    sm,
    setBackgroundStyle,
  };

  return <AreaContext.Provider value={state}>{children}</AreaContext.Provider>;
}
