import { HTMLSelect } from "@blueprintjs/core";
import { styled } from "styled-components";
import { ChartBackgroundStyleEnum } from "../../../../../__generated__/gql/graphql";
import { useArea } from "../../../contexts/AreaContext";

const Container = styled.div`
  position: absolute;
  left: 24px;
  bottom: 24px;
`;

export default function BackgroundSelector() {
  const { background, setBackgroundStyle } = useArea();

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setBackgroundStyle(e.target.value as ChartBackgroundStyleEnum);
  };

  return (
    <Container>
      <HTMLSelect onChange={handleChange} value={background.style}>
        {Object.values(ChartBackgroundStyleEnum).map((style) => (
          <option key={style} value={style}>
            {style}
          </option>
        ))}
      </HTMLSelect>
    </Container>
  );
}
