import { styled } from "styled-components";

import { useAreaLocalCoords } from "../../../hooks/useAreaLocalCoords";

import PositionMarker from "./PositionMarker";

const Container = styled.div.attrs<{
  $x: number;
  $y: number;
}>((props) => ({
  style: {
    left: `${props.$x}px`,
    top: `${props.$y}px`,
  },
}))`
  position: absolute;
`;

export type FakeLocationMarkerProps = {
  longitude: number;
  latitude: number;
};

export default function FakeLocationMarker({
  longitude,
  latitude
}: FakeLocationMarkerProps) {
  const { x, y } = useAreaLocalCoords(
    longitude,
    latitude,
  );

  return (
    <Container
      $x={x}
      $y={y}
    >
      <PositionMarker />
    </Container>
  );
}
