import styled from "styled-components";

const Container = styled.div<{ $color: string }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${(props) => props.$color};
`;

export type GroupColorIndicatorProps = {
  color: string;
};

export function GroupColorIndicator({ color }: GroupColorIndicatorProps) {
  return <Container $color={color} />;
}
