import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";
import { Camera, Quaternion, Vector3 } from "three";
import { useUserSocket } from "../../../../context/UserSocketContext";
import { SelectionType, useSelection } from "../../contexts/SelectionContext";
import { Channel } from "phoenix";
import { useArea } from "../../contexts/AreaContext";

// Explanation:
//  https://gamedev.stackexchange.com/questions/157946/converting-a-quaternion-in-a-right-to-left-handed-coordinate-system
//
//          Irr   Three
//  right   X     X
//  up      Y     Y
//  forward Z     -Z
//
//  Irrlicht uses left-handed coordinate system where Three (same as OpenGl) uses right-handed
function ConvertToIrrlicht(q: Quaternion)
{
  q.x *= -1;
  q.y *= -1;
}

// Curently our IMU uses right-handed cord system + axes Y/Z are flipped compared to Irrlicht.
// To mimic that we do another coordinate system flip
function ConvertToIMU(q: Quaternion)
{
  const Y = q.y;

  q.x *= -1;
  q.y = -q.z;
  q.z = -Y;
}

// Also IMU chip on prototype headphones is rotated 90 degrees
// Undertone player undos this so again... we need to mimic real IMU placement
function MimicPhisicalSensorOrientation(q: Quaternion)
{
  let qPhys = new Quaternion();
  qPhys.setFromAxisAngle(new Vector3(0,1,0), Math.PI / -2);
  
  q.multiply(qPhys);
}

export default function DeviceForwarder() {
  const cameraRef = useRef<Camera>();
  const previousCameraQuaternion = useRef<Quaternion>();
  const previousCameraPosition = useRef<Vector3>();
  const { camera } = useThree();
  const { userSocket } = useUserSocket();
  const { selectionId, selectionType } = useSelection();
  const [channel, setChannel] = useState<Channel | undefined>();

  const { area, sm } = useArea();

  useEffect(() => {
    if(selectionType !== SelectionType.ClientDevice) return;

    const channelName = `device:${selectionId}`;
    const channel = userSocket!.channel(channelName, {});
    
    console.debug(`[UserSocket] Joining channel ${channelName}`);
    channel.join()
      .receive("ok", () => {
        console.debug(`[UserSocket] Joined channel ${channelName}`);
        setChannel(channel);
      })
      .receive("error", (resp) => {
        console.warn(`[UserSocket] Failed to join channel ${channelName}`, resp);
      });

    return () => {
      if(!channel) return;

      console.debug(`[UserSocket] Leaving channel ${channelName}`);
      channel.leave();
      setChannel(undefined);
    }
  }, [userSocket, selectionType, selectionId, setChannel]);

  useEffect(() => {
    cameraRef.current = camera;
  }, [camera]);

  useFrame(() => {
    if(!cameraRef.current) return;

    const cameraPosition = cameraRef.current.position;
    if(!previousCameraPosition.current || !previousCameraPosition.current.equals(cameraPosition)) {
      const ll = sm.ll([cameraPosition.x + area.localOffsetX, cameraPosition.z + area.localOffsetY], area.backgroundZoom);
      if(channel) {
        channel.push("control", {event: [
          {
            name: "fakeGpsPositionChanged",
            past: false,
            timestamp: new Date().toISOString(),
            payload: {
              longitude: ll[0],
              latitude: ll[1],              
            }
          }
        ]})
      }
      previousCameraPosition.current = cameraPosition.clone();

      // const dataDiv = document.getElementById('dataGps');
      // dataDiv!.innerHTML = `<ul>
      // <li>lng: ${ll[0]}</li>
      // <li>lat: ${ll[1]}</li>
      // <li>x relative: ${cameraPosition.x} (camera x = area x)</li>
      // <li>y relative: ${cameraPosition.z} (camera z = area y)</li>
      // <li>x absolute: ${cameraPosition.x + area.localOffsetX} (camera x = area x)</li>
      // <li>y absolute: ${cameraPosition.z + area.localOffsetY} (camera z = area y)</li>
      // </ul>`;
    }
    
    const cameraQuaternion = cameraRef.current.quaternion;
    if(!previousCameraQuaternion.current || !previousCameraQuaternion.current.equals(cameraQuaternion)) {
      if(channel)
      {
        var q = cameraRef.current.quaternion.clone();
        // DO NOT CHANGE transformation order below!
        ConvertToIrrlicht(q);
        MimicPhisicalSensorOrientation(q);
        ConvertToIMU(q);

        channel.push("control", {event: [
          {
            name: "fakeImuPositionChanged",
            past: false,
            timestamp: new Date().toISOString(),
            payload: {
              x: q.x,
              y: q.y,
              z: q.z,
              w: q.w,
            }
          }
        ]})
      }
      previousCameraQuaternion.current = cameraQuaternion.clone();
      
      // const dataDiv = document.getElementById('dataImu');
      // dataDiv!.innerHTML = `
      // <ul>
      // <li>x: ${cameraQuaternion.x}</li>
      // <li>y: ${cameraQuaternion.y}</li>
      // <li>z: ${cameraQuaternion.z}</li>
      // <li>w: ${cameraQuaternion.w}</li>
      // </ul>`;
    }
  });

  return null;
}