import { DocumentNode, useMutation } from "@apollo/client";
import { Alert } from "@blueprintjs/core";
import { useCallback } from "react";

export type DeleteAlertProps = {
  mutation: DocumentNode;
  recordId?: string;
  recordName?: string;
  isOpen: boolean;
  onClose: (deleted: boolean) => void;
};

export default function DeleteAlert({
  mutation,
  recordId,
  recordName,
  isOpen,
  onClose,
}: DeleteAlertProps) {
  const [deleteAction, { loading }] = useMutation(mutation);

  const handleConfirm = useCallback(async () => {
    await deleteAction({ variables: { id: recordId } });
    onClose(true);
  }, [recordId, deleteAction, onClose]);

  const handleCancel = () => {
    onClose(false);
  };

  return (
    <Alert
      isOpen={isOpen}
      intent="danger"
      icon="trash"
      loading={loading}
      cancelButtonText="Cancel"
      confirmButtonText="Delete permanently"
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      canOutsideClickCancel={false}
      canEscapeKeyCancel={true}
    >
      Are you sure you want to delete <b>{recordName}</b>? This operation is not
      reversible.
    </Alert>
  );
}
