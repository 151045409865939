import { ReactNode } from "react";
import { styled } from "styled-components";

const Container = styled.div``;

const TitleContainer = styled.div``;

const BodyContainer = styled.div`
  background: #fff;
`;

export type PanelContainerProps = {
  children: ReactNode;
  title: string;
};

export default function PanelContainer({
  children,
  title,
}: PanelContainerProps) {
  return (
    <Container>
      <TitleContainer>{title}</TitleContainer>
      <BodyContainer>{children}</BodyContainer>
    </Container>
  );
}
