import { FormGroup, InputGroup, Intent, NumericInput } from "@blueprintjs/core";
import { ReactNode, useCallback } from "react";
import { EditFormFieldDefinition, EditFormFieldValue } from "./useEditForm";

export type EditFormFieldProps = {
  formField: EditFormFieldDefinition;
  value: any;
  onChange: (value: EditFormFieldValue) => void;
};

export default function EditFormField({
  formField,
  value,
  onChange,
}: EditFormFieldProps) {
  const htmlId = `edit-form-${formField.name}`;

  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement;
      onChange({ casted: target.value });
    },
    [onChange],
  );

  const handleIntChange = (_valueAsNumber: number, valueAsString: string) => {
    // Handle string not number as per Blueprint JS docs
    if (valueAsString.match(/^[+-]?([0-9]*)$/)) {
      onChange({
        casted: parseInt(valueAsString, 10),
        internal: valueAsString,
      });
    }
  };

  const handleFloatChange = (_valueAsNumber: number, valueAsString: string) => {
    // Handle string not number as per Blueprint JS docs
    if (valueAsString.match(/^[+-]?([0-9]*[.])?[0-9]*$/)) {
      onChange({ casted: parseFloat(valueAsString), internal: valueAsString });
    }
  };

  let input: ReactNode;
  switch (formField.type) {
    case "ID":
      input = (
        <NumericInput
          fill
          id={htmlId}
          value={(value && value.internal && value.internal.toString()) || ""}
          onValueChange={handleIntChange}
          intent={formField.invalid ? Intent.DANGER : Intent.NONE}
        />
      );
      break;

    case "String":
      input = (
        <InputGroup
          id={htmlId}
          value={(value && value.casted) || ""}
          onChange={handleChange}
          intent={formField.invalid ? Intent.DANGER : Intent.NONE}
        />
      );
      break;

    case "Float":
      input = (
        <NumericInput
          fill
          id={htmlId}
          value={(value && value.internal && value.internal.toString()) || ""}
          onValueChange={handleFloatChange}
          intent={formField.invalid ? Intent.DANGER : Intent.NONE}
        />
      );
      break;

    default:
      throw new Error(
        `EditFormField for ${formField.name} has unknown type ${formField.type}`,
      );
  }

  return (
    <FormGroup
      key={formField.name}
      label={formField.name}
      labelFor={htmlId}
      labelInfo={formField.required && "(required)"}
      inline
      intent={formField.invalid ? Intent.DANGER : Intent.NONE}
      helperText={formField.invalidReason && formField.invalidReason.join("; ")}
    >
      {input}
    </FormGroup>
  );
}
