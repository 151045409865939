import { styled } from "styled-components";

const Vertical = styled.div`
  position: absolute;
  height: 24px;
  width: 1px;
  background: #000;
  top: -12px;
  left: 0;
`;

const Horizontal = styled.div`
  position: absolute;
  width: 24px;
  height: 1px;
  background: #000;
  left: -12px;
  top: 0;
`;

export default function PositionMarker() {
  return (
    <>
      <Horizontal />
      <Vertical />
    </>
  );
}
