import { useQuery } from "@apollo/client";
import {
  ChartArea,
  EditorReadAreaDocument,
} from "../../__generated__/gql/graphql";
import { Button, NonIdealState, Spinner } from "@blueprintjs/core";
import Workspace from "./features/Workspace";
import { AreaProvider } from "./contexts/AreaContext";
import { ReactNode, useEffect, useState } from "react";
import { CanvasKit } from "canvaskit-wasm";
import { CanvasKitProvider } from "./contexts/CanvasKitContext";
import { styled } from "styled-components";
import { useParams } from "react-router";

type CanvasKitLoaderProps = {
  children: ReactNode;
};

const NonIdealContainer = styled.div`
  width: 100%;
  padding: 48px;
`;

/**
 * Polls state of the global CanvasKit object until it is loaded.
 */
function useCanvasKitGlobal() {
  const [instance, setInstance] = useState<CanvasKit | undefined>(
    window.canvasKit.instance,
  );
  const [loading, setLoading] = useState(window.canvasKit.loading);
  const [error, setError] = useState(window.canvasKit.error);

  useEffect(() => {
    // Quit if already loaded
    if (!loading && !error && instance) {
      return;
    }

    const intervalId = setInterval(() => {
      setInstance(window.canvasKit.instance);
      setLoading(window.canvasKit.loading);
      setError(window.canvasKit.error);
    }, 100);

    return () => {
      clearInterval(intervalId);
    };
  }, [loading, error, instance]);

  return { instance, loading, error };
}

/**
 * Ensures that CanvasKit is loaded end exposes it using context.
 */
function CanvasKitLoader({ children }: CanvasKitLoaderProps) {
  const { instance, loading, error } = useCanvasKitGlobal();

  if (loading) {
    return (
      <div>
        <Spinner style={{ padding: 60 }} />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ padding: 60 }}>
        <NonIdealState
          icon="error"
          title="Loading error"
          description="Failed to initialize CanvasKit"
        />
      </div>
    );
  }

  return <CanvasKitProvider value={instance!}>{children}</CanvasKitProvider>;
}

type DataLoaderProps = {
  areaId: string;
  children: ReactNode;
};

/**
 * Loads data necessary for editor to operate.
 */
function DataLoader({ areaId, children }: DataLoaderProps) {
  const { loading, error, data, refetch } = useQuery(EditorReadAreaDocument, {
    variables: { id: areaId },
    pollInterval: 1000, // FIXME
  });

  if (loading) {
    return (
      <div>
        <Spinner style={{ padding: 60 }} />
      </div>
    );
  }

  if (error) {
    return (
      <NonIdealContainer>
        <NonIdealState
          icon="error"
          title="Loading error"
          description={error.message}
          action={
            <Button
              disabled={loading}
              text="Reload"
              onClick={() => refetch()}
            />
          }
        />
      </NonIdealContainer>
    );
  }

  if (!data?.chartArea) {
    return (
      <NonIdealContainer>
        <NonIdealState icon="cross" title="No such chart" />
      </NonIdealContainer>
    );
  }

  // FIXME why is casting needed?
  return (
    <AreaProvider area={data?.chartArea as ChartArea}>{children}</AreaProvider>
  );
}

export default function EditorIndex() {
  const { areaId } = useParams();

  if (!areaId) {
    return (
      <NonIdealContainer>
        <NonIdealState icon="cross" title="Missing area ID parameter" />
      </NonIdealContainer>
    );
  }

  return (
    <CanvasKitLoader>
      <DataLoader areaId={areaId}>
        <Workspace />
      </DataLoader>
    </CanvasKitLoader>
  );
}
