import { HTMLTable } from "@blueprintjs/core";
import { ClientDevice } from "../../../../../__generated__/gql/graphql";
import { useArea } from "../../../contexts/AreaContext";
import PanelContainer from "../PanelContainer";
import { styled } from "styled-components";
import {
  SelectionType,
  useSelection,
} from "../../../contexts/SelectionContext";
import OnlineIndicator from "../../../components/OnlineIndicator";

const DeviceRow = styled.tr<{ 
  $selected: boolean, 
}>`
  font-weight: ${(props) => (props.$selected ? "bold" : "normal")};
`;


type DeviceItemProps = {
  device: ClientDevice;
};

function DeviceItem({ device }: DeviceItemProps) {
  const { selectionType, selectionId, setSelection } = useSelection();

  const handleClick = () => {
    setSelection(SelectionType.ClientDevice, device.id);
  };

  const selected =
    selectionType === SelectionType.ClientDevice &&
    selectionId === device.id;

  return (
    <DeviceRow $selected={selected} onClick={handleClick}>
      <td>{device.hostname}</td>
      <td><OnlineIndicator online={!!device.connectedAt} /></td>
    </DeviceRow>
  );
}

const HTMLTableWide = styled(HTMLTable)`
  width: 100%;
`;

export default function DevicesPanel() {
  const { area } = useArea();

  return (
    <PanelContainer title="Devices">
      <HTMLTableWide compact interactive>
        <tbody>
          {area.devices.map((device) => (
            <DeviceItem key={device.id} device={device} />
          ))}
        </tbody>
      </HTMLTableWide>
    </PanelContainer>
  );
}
