import { useMutation } from "@apollo/client";
import { Button, FileInput, ProgressBar } from "@blueprintjs/core";
import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AdminCreateFileDocument,
  AdminUpdateChunkDocument,
} from "../../../../__generated__/gql/graphql";

const sleep = (duration: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, duration);
  });
};

function useUpload(localFile: File | undefined) {
  const [createFile] = useMutation(AdminCreateFileDocument);
  const [updateFileChunk] = useMutation(AdminUpdateChunkDocument);

  const progressCurrentRef = useRef<number>(0);
  const progressTotal = useMemo(
    () => (localFile ? localFile.size : undefined),
    [localFile],
  );
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    progressCurrentRef.current = 0;
  }, [localFile]);

  // TODO case when file is changed during upload
  // TODO case when upload is invoked during upload

  const upload = useCallback(async () => {
    if (!localFile) throw new Error("Unable to upload: no file is set");
    setUploading(true);

    // Create file via API
    const type =
      localFile.type === "" ? "application/octet-stream" : localFile.type;
    const serverFile = await createFile({
      variables: {
        name: localFile.name,
        size: localFile.size,
        type,
      },
    });

    // Upload chunks to S3
    // FIXME ?! should not be needed
    const chunks = serverFile.data!.createAssetSoundFile!.chunks!;
    for (const chunk of chunks) {
      if (!chunk) throw new Error("Unexpected null chunk");

      while (true) {
        try {
          const body = localFile.slice(chunk.offset, chunk.offset + chunk.size);
          const response = await fetch(chunk.uploadUrl!, {
            method: chunk.uploadMethod,
            body,
          });
          const etag = response.headers.get("ETag")!; // FIXME proper check

          await updateFileChunk({
            variables: {
              id: chunk.id,
              etag,
            },
          });

          progressCurrentRef.current += chunk.size;
          break;
        } catch (e) {
          console.warn("Error while uploading chunk", chunk, e);
          await sleep(1000);
        }
      }
    }

    // Done
    setUploading(false);
    progressCurrentRef.current = 0;
    return serverFile.data!.createAssetSoundFile!.id;
  }, [
    localFile,
    createFile,
    updateFileChunk,
    setUploading,
    progressCurrentRef,
  ]);

  const progressCurrent = progressCurrentRef.current;
  return { upload, uploading, progressTotal, progressCurrent };
}

export default function SoundFileUpload() {
  const [localFile, setLocalFile] = useState<File | undefined>(undefined);
  const { upload, uploading, progressTotal, progressCurrent } =
    useUpload(localFile);

  const handleFileChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setLocalFile(event.target!.files![0]);
  };

  const handleUpload = () => {
    upload();
  };

  return (
    <div>
      <h1>Upload</h1>
      <div>
        <FileInput
          onInputChange={handleFileChange}
          disabled={uploading}
          text={localFile && localFile.name}
        />
        <Button
          onClick={handleUpload}
          text="Upload"
          disabled={uploading || !localFile || localFile.size === 0}
        />
      </div>

      {uploading && progressTotal && (
        <ProgressBar value={(progressCurrent * 1.0) / progressTotal} />
      )}
    </div>
  );
}
