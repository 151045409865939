export enum BakeMode {
  Volume = "volume",
  EditorRegular = "editor-regular",
  EditorSelected = "editor-selected",
  EditorHover = "editor-hover",
}

export type BakeGlobalParams = {
  pixelToMetricRatio: number;
};
