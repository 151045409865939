import {
  ChartSoundSource,
  EditorUpdateFadeValueDocument,
} from "../../../../../../__generated__/gql/graphql";
import { useMutation } from "@apollo/client";
import { Button, ButtonGroup } from "@blueprintjs/core";
import { useCallback } from "react";
import { FADE_SLIDER_MAX } from "./FadeSlider";

type PresetFunc = (position: number, count: number) => number;

function presetLinear(position: number, count: number) {
  const step = 1.0 / (count - 1);
  return FADE_SLIDER_MAX - (position - 1) * step * FADE_SLIDER_MAX;
}

function presetCosine(position: number, count: number) {
  const step = 1.0 / (count - 1);
  return (
    ((Math.cos((position - 1) * step * Math.PI) + 1) * FADE_SLIDER_MAX) / 2.0
  );
}

const LOG_LIMIT = 0.03;

function presetLog(position: number, count: number) {
  const step = 1.0 / (count - 1);
  const base = position === 1 ? LOG_LIMIT : (position - 1) * step;

  const max = Math.log10(LOG_LIMIT) * -1;
  return ((Math.log10(base) * -1) / max) * FADE_SLIDER_MAX;
}

// FIXME check if this is correct equation
function presetEqualPower(position: number, count: number) {
  const step = 1.0 / (count - 1);
  const base = ((position - 1) * step * Math.PI) / 2.0;
  return Math.cos(base) * FADE_SLIDER_MAX;
}

export type FadePresetsProps = {
  source: ChartSoundSource;
};

export function FadePresets({ source }: FadePresetsProps) {
  const [updateValue] = useMutation(EditorUpdateFadeValueDocument);

  const handlePreset = useCallback(
    (presetFunc: PresetFunc) => {
      source.fades.forEach((fade) => {
        updateValue({
          variables: {
            id: fade.id,
            value: presetFunc(fade.position, source.fades.length),
          },
        });
      });
    },
    [updateValue, source.fades],
  );

  const handleLog = useCallback(() => {
    handlePreset(presetLog);
  }, [handlePreset]);

  const handleLinear = useCallback(() => {
    handlePreset(presetLinear);
  }, [handlePreset]);

  const handleCosine = useCallback(() => {
    handlePreset(presetCosine);
  }, [handlePreset]);

  const handleEqualPower = useCallback(() => {
    handlePreset(presetEqualPower);
  }, [handlePreset]);

  return (
    <ButtonGroup>
      <Button text="Log" onClick={handleLog} />
      <Button text="Linear" onClick={handleLinear} />
      <Button text="Cosine" onClick={handleCosine} />
      <Button text="Equal Power" onClick={handleEqualPower} />
    </ButtonGroup>
  );
}
