import { Button, ButtonGroup } from "@blueprintjs/core";
import { styled } from "styled-components";
import { useArea } from "../../contexts/AreaContext";
import SphericalMercator from "@mapbox/sphericalmercator";
import { useMutation } from "@apollo/client";
import { EditorCreateSourceDocument } from "../../../../__generated__/gql/graphql";
import { useCallback } from "react";
import { WorkspacePreviewMode } from "../Workspace";

export const TOOLBOX_SIDEBAR_WIDTH = 40;

const OuterContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: ${TOOLBOX_SIDEBAR_WIDTH}px;
  background: #666;
`;

const InnerContainer = styled.div`
  position: absolute;
  left: 4px;
  top: 4px;
`;

export type ToolboxSidebarProps = {
  previewMode: WorkspacePreviewMode;
  changePreviewMode: (newPreviewMode: WorkspacePreviewMode) => void;
}

export default function ToolboxSidebar({ previewMode, changePreviewMode }: ToolboxSidebarProps) {
  const { area } = useArea();
  const [createSource] = useMutation(EditorCreateSourceDocument);

  const handleCreateSource = useCallback(() => {
    const x = area.localOffsetX + area.localWidth / 2.0;
    const y = area.localOffsetY + area.localHeight / 2.0;

    const sm = new SphericalMercator({ size: area.backgroundTileSize });
    const ll = sm.ll([x, y], area.backgroundZoom);

    const radius = 30;
    const name = `Source ${area.sources.length + 1}`;

    createSource({
      variables: {
        areaId: area.id,
        name,
        globalLongitude: ll[0],
        globalLatitude: ll[1],
        radius,
      },
    });
  }, [area, createSource]);

  const handlePreview2D = useCallback(() => {
    changePreviewMode('2d');
  }, [changePreviewMode]);

  const handlePreview3D = useCallback(() => {
    changePreviewMode('3d');
  }, [changePreviewMode]);

  return (
    <OuterContainer>
      <InnerContainer>
        <ButtonGroup vertical>
          <Button
            icon="plus"
            title="Add Sound Source"
            onClick={handleCreateSource}
          />
        </ButtonGroup>

        <ButtonGroup vertical style={{marginTop: 16}}>
          <Button
            icon="map"
            title="2D"
            onClick={handlePreview2D}
            active={previewMode === '2d'}
          />
          <Button
            icon="camera"
            title="3D"
            onClick={handlePreview3D}
            active={previewMode === '3d'}
          />
        </ButtonGroup>
      </InnerContainer>
    </OuterContainer>
  );
}
