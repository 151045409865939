import { Button, Dialog, DialogBody, DialogFooter } from "@blueprintjs/core";
import { DocumentNode } from "graphql";
import { useCallback } from "react";
import EditForm from "./EditForm";
import { useEditForm } from "./useEditForm";
import { RecordRow } from ".";

export type UpdateDialogProps = {
  mutation: DocumentNode;
  isOpen: boolean;
  onClose: (created: boolean) => void;
  model: string;
  record: RecordRow;
};

export default function UpdateDialog({
  mutation,
  isOpen,
  onClose,
  model,
  record,
}: UpdateDialogProps) {
  const formSchema = useEditForm(mutation, record);
  const { submit, invalid, loading } = formSchema;

  const handleClick = useCallback(async () => {
    const success = await submit();
    if (success) onClose(true);
  }, [submit, onClose]);

  const handleCancel = () => {
    onClose(false);
  };

  return (
    <Dialog
      title={`Update ${model}`}
      isOpen={isOpen}
      onClose={handleCancel}
      canOutsideClickClose={false}
      canEscapeKeyClose={true}
    >
      <DialogBody>
        <EditForm formSchema={formSchema} />
      </DialogBody>
      <DialogFooter
        actions={
          <>
            <Button text="Cancel" onClick={handleCancel} />
            <Button
              loading={loading}
              intent="warning"
              text="Update"
              onClick={handleClick}
              disabled={invalid}
            />
          </>
        }
      />
    </Dialog>
  );
}
