import { useQuery } from "@apollo/client";
import {
  Button,
  ButtonGroup,
  HTMLTable,
  NonIdealState,
  Spinner,
} from "@blueprintjs/core";
import { DocumentNode } from "graphql";
import { Maybe } from "../../../../__generated__/gql/graphql";
import { ReactNode, useMemo, useState } from "react";
import { styled } from "styled-components";
import DeleteAlert from "./DeleteAlert";
import CreateDialog from "./CreateDialog";
import UpdateDialog from "./UpdateDialog";
import { useNavigate } from "react-router";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const NonIdealContainer = styled.div`
  width: 100%;
  padding: 48px;
`;

const LoadingContainer = styled.div`
  width: 100%;
  padding: 48px;
`;

const ActionBar = styled.div`
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ActionBarCustom = styled.div`
  display: flex;
`;

const ActionBarStandard = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Table = styled(HTMLTable)`
  width: 100%;
`;

export type RecordRow = Record<string, any> & {
  id?: Maybe<string> | undefined;
};

export type RecordColumn<RecordType extends RecordRow> = {
  name: string;
  renderer?: (record: RecordType, columnName: string, value: any) => ReactNode;
};

export type RecordTableProps<RecordType extends RecordRow> = {
  actions?: ReactNode;
  createMutation?: DocumentNode;
  createText?: string;
  createUrl?: string;
  columns: RecordColumn<RecordType>[];
  dataKey: string;
  deleteMutation?: DocumentNode;
  model: string;
  query: DocumentNode;
  recordNameKey: string;
  updateMutation?: DocumentNode;
};

export function RecordTable<RecordType extends RecordRow>({
  actions,
  createMutation,
  createText,
  createUrl,
  columns,
  dataKey,
  deleteMutation,
  model,
  recordNameKey,
  query,
  updateMutation,
}: RecordTableProps<RecordType>) {
  const { loading, error, data, refetch } = useQuery(query, {
    pollInterval: 5000,
  });
  const navigate = useNavigate();

  // Features
  const createEnabled = !!createMutation;
  const updateEnabled = !!updateMutation;
  const deleteEnabled = !!deleteMutation;

  // CRUD state
  const [creating, setCreating] = useState(false);
  const [updatedRecord, setUpdatedRecord] = useState<RecordType | undefined>(
    undefined,
  );
  const [deletedRecord, setDeletedRecord] = useState<RecordType | undefined>(
    undefined,
  );

  // Action Bar
  const createButton = useMemo(() => {
    if (createUrl) {
      return (
        <Button
          icon="plus"
          text={createText || "Create"}
          onClick={() => navigate(createUrl)}
        />
      );
    }

    if (createEnabled) {
      return (
        <Button
          icon="plus"
          text={createText || "Create"}
          onClick={() => setCreating(true)}
        />
      );
    }
  }, [createText, setCreating, createEnabled, createUrl, navigate]);

  const reloadButton = useMemo(
    () => (
      <Button
        loading={loading}
        icon="refresh"
        text={"Reload"}
        onClick={() => refetch()}
      />
    ),
    [loading, refetch],
  );

  const modals = useMemo(
    () => (
      <>
        {createEnabled && (
          <CreateDialog
            isOpen={creating}
            mutation={createMutation}
            onClose={(created) => {
              setCreating(false);
              created && refetch();
            }}
            model={model}
          />
        )}
        {updateEnabled && !!updatedRecord && (
          <UpdateDialog
            isOpen={!!updatedRecord}
            mutation={updateMutation}
            onClose={(updated) => {
              setUpdatedRecord(undefined);
              updated && refetch();
            }}
            model={model}
            record={updatedRecord}
          />
        )}

        {deleteEnabled && (
          <DeleteAlert
            isOpen={!!deletedRecord}
            recordId={deletedRecord && deletedRecord.id!}
            recordName={deletedRecord && deletedRecord[recordNameKey]!}
            mutation={deleteMutation}
            onClose={(deleted) => {
              setDeletedRecord(undefined);
              deleted && refetch();
            }}
          />
        )}
      </>
    ),
    [
      createEnabled,
      creating,
      createMutation,
      model,
      updateEnabled,
      updatedRecord,
      updateMutation,
      deleteEnabled,
      deletedRecord,
      deleteMutation,
      refetch,
      recordNameKey,
    ],
  );

  // Non-positive states
  if (loading)
    return (
      <LoadingContainer>
        <Spinner />
      </LoadingContainer>
    );

  if (error)
    return (
      <NonIdealContainer>
        <NonIdealState
          icon="error"
          title="Loading error"
          description={error.message}
          action={reloadButton}
        />
      </NonIdealContainer>
    );

  if (data[dataKey].length === 0) {
    return (
      <>
        {modals}

        <NonIdealContainer>
          <NonIdealState
            icon="cross"
            title="No data to show"
            action={
              <ButtonGroup>
                {createButton}
                {reloadButton}
              </ButtonGroup>
            }
          />
        </NonIdealContainer>
      </>
    );
  }

  // Positive state
  return (
    <>
      {modals}

      <Container>
        <ActionBar>
          <ActionBarCustom>
            <ButtonGroup>{createButton}</ButtonGroup>
            {actions}
          </ActionBarCustom>

          <ActionBarStandard>{reloadButton}</ActionBarStandard>
        </ActionBar>

        <Table>
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.name}>{column.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data[dataKey].map((record: RecordType) => (
              <tr key={record.id}>
                {columns.map((column) => (
                  <td key={column.name}>
                    {column.renderer
                      ? column.renderer(
                          record,
                          column.name,
                          record[column.name],
                        )
                      : record[column.name]}
                  </td>
                ))}

                <td>
                  {updateEnabled && (
                    <Button
                      minimal
                      small
                      icon="edit"
                      title="Edit"
                      onClick={() => setUpdatedRecord(record)}
                    />
                  )}
                  {deleteEnabled && (
                    <Button
                      minimal
                      small
                      icon="trash"
                      title="Delete"
                      onClick={() => setDeletedRecord(record)}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </>
  );
}
