import { useCallback, useMemo, useState } from "react";
import { useArea } from "../../../contexts/AreaContext";
import { useSet } from "../../../hooks/useSet";
import { Icon, Tree, TreeNodeInfo } from "@blueprintjs/core";
import { SelectionType } from "../../../contexts/SelectionContext";
import {
  EditorUpdateGroupNameDocument,
  EditorUpdateSourceNameDocument,
} from "../../../../../__generated__/gql/graphql";
import { useMutation } from "@apollo/client";
import { TreeEditableLabel } from "./TreeEditableLabel";
import { GroupColorIndicator } from "./GroupColorIndicator";

export const CONTENT_TREE_PREFIX_SOURCE = "source-";
export const CONTENT_TREE_PREFIX_GROUP = "group-";

export type ContentTreeProps = {
  selectionType: SelectionType;
  selectionId: string;
  onSelectionChange: (
    newSelectionType: SelectionType,
    newSelectionId: string,
  ) => void;
  enableSources: boolean;
  editable: boolean;
  disabledGroupIds?: (string | number)[];
};

export function ContentTree({
  selectionType,
  selectionId,
  onSelectionChange,
  enableSources,
  editable,
  disabledGroupIds,
}: ContentTreeProps) {
  const { area } = useArea();

  // Node expansion

  const [expandedTreeNodeIds, addExpandedNodeId, removeExpandedNodeId] = useSet<
    string | number
  >();

  const handleNodeExpand = useCallback(
    (node: TreeNodeInfo) => {
      addExpandedNodeId(node.id);
    },
    [addExpandedNodeId],
  );

  const handleNodeCollapse = useCallback(
    (node: TreeNodeInfo) => {
      removeExpandedNodeId(node.id);
    },
    [removeExpandedNodeId],
  );

  // Node selection

  const handleNodeClick = useCallback(
    (node: TreeNodeInfo) => {
      if (typeof node.id !== "string") {
        throw new Error(`Unexpected node id that is not a string: ${node.id}`);
      }

      if (node.id.startsWith(CONTENT_TREE_PREFIX_GROUP)) {
        const groupId = node.id.replace(CONTENT_TREE_PREFIX_GROUP, "");
        onSelectionChange(SelectionType.ChartSoundGroup, groupId);
      } else if (node.id.startsWith(CONTENT_TREE_PREFIX_SOURCE)) {
        const sourceId = node.id.replace(CONTENT_TREE_PREFIX_SOURCE, "");
        onSelectionChange(SelectionType.ChartSoundSource, sourceId);
      } else {
        throw new Error(`Unknown node id: ${node.id}`);
      }
    },
    [onSelectionChange],
  );

  // Node name editing

  const [updateGroupName] = useMutation(EditorUpdateGroupNameDocument);
  const [updateSourceName] = useMutation(EditorUpdateSourceNameDocument);
  const [editedTreeNodeId, setEditedTreeNodeId] = useState<
    string | number | null
  >(null);

  const handleNodeDoubleClick = useCallback(
    (node: TreeNodeInfo) => {
      if (editable) {
        setEditedTreeNodeId(node.id);
      }
    },
    [setEditedTreeNodeId, editable],
  );

  // Displaying

  const contents = useMemo(() => {
    const groupMap: { [key: string]: TreeNodeInfo } = {};
    const rootNodes: TreeNodeInfo[] = [];

    // Initialize tree nodes for all groups
    for (const group of area.groups) {
      const id = `group-${group.id}`;
      const label =
        editedTreeNodeId === id ? (
          <TreeEditableLabel
            defaultValue={group.name}
            onSave={(newName: string) => {
              updateGroupName({
                variables: { id: group.id, name: newName },
              });
              setEditedTreeNodeId(null);
            }}
            onCancel={() => setEditedTreeNodeId(null)}
          />
        ) : (
          group.name
        );
      const isSelected =
        selectionType === SelectionType.ChartSoundGroup &&
        selectionId === group.id;

      const disabled = disabledGroupIds && disabledGroupIds.includes(group.id);

      const colorIndicator = <GroupColorIndicator color={group.color} />;

      const secondaryLabel = group.visible ? (
        colorIndicator
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "8px",
          }}
        >
          <Icon icon="eye-off" /> {colorIndicator}
        </div>
      );

      groupMap[group.id] = {
        id,
        label,
        icon: "folder-close",
        isExpanded: expandedTreeNodeIds.includes(id),
        isSelected,
        childNodes: [],
        hasCaret: false,
        disabled,
        secondaryLabel,
      };
    }

    // Assign groups to their parent nodes or root level
    for (const group of area.groups) {
      const treeNode = groupMap[group.id];
      if (group.parentId) {
        const parent = groupMap[group.parentId];
        if (parent) {
          parent.childNodes!.push(treeNode);
          parent.hasCaret = true;
        }
      } else {
        rootNodes.push(treeNode);
      }
    }

    // Assign sources to their respective groups or root level
    if (enableSources) {
      for (const source of area.sources) {
        const id = `source-${source.id}`;
        const label =
          editedTreeNodeId === id ? (
            <TreeEditableLabel
              defaultValue={source.name}
              onSave={(newName: string) => {
                updateSourceName({
                  variables: { id: source.id, name: newName },
                });
                setEditedTreeNodeId(null);
              }}
              onCancel={() => setEditedTreeNodeId(null)}
            />
          ) : (
            source.name
          );
        const isSelected =
          selectionType === SelectionType.ChartSoundSource &&
          selectionId === source.id;

        const sourceNode: TreeNodeInfo = {
          id,
          label,
          icon: "music",
          isSelected,
          isExpanded: false,
        };
        if (source.groupId) {
          const groupNode = groupMap[source.groupId];
          if (groupNode) {
            groupNode.childNodes!.push(sourceNode);
            groupNode.hasCaret = true;
          }
        } else {
          rootNodes.push(sourceNode);
        }
      }
    }

    return rootNodes;
  }, [
    area.groups,
    area.sources,
    editedTreeNodeId,
    setEditedTreeNodeId,
    updateSourceName,
    updateGroupName,
    selectionId,
    selectionType,
    expandedTreeNodeIds,
    enableSources,
    disabledGroupIds,
  ]);

  return (
    <Tree
      contents={contents}
      onNodeExpand={handleNodeExpand}
      onNodeCollapse={handleNodeCollapse}
      onNodeClick={handleNodeClick}
      onNodeDoubleClick={handleNodeDoubleClick}
    />
  );
}
