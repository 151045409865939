import { RecordTable } from "../../components/RecordTable";
import {
  AssetSoundFile,
  AdminDeleteFileDocument,
  AdminReadFilesDocument,
} from "../../../../__generated__/gql/graphql";
import DateTimeCell from "../../components/RecordTable/cell/DateTimeCell";
import FileSizeCell from "../../components/RecordTable/cell/FileSizeCell";
import { AnchorButton, Button } from "@blueprintjs/core";
import { styled } from "styled-components";
import { useState } from "react";

const PLAYER_HEIGHT = 90;

const PlayerContainer = styled.div.attrs<{
  $playerEnabled: boolean;
}>((props) => ({
  style: {
    display: props.$playerEnabled ? "block" : "none",
  },
}))`
  position: absolute;
  position: fixed;
  background: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  height: ${PLAYER_HEIGHT}px;
  border-top: solid 1px #e0e0e0;
`;

const PlayerHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PlayerActions = styled.div`
  margin-left: 12px;
  margin-right: 12px;
`;

const PlayerSource = styled.audio`
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
`;

const PlayerTitle = styled.div`
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 4px;
  margin-bottom: 4px;
`;

const TableContainer = styled.div.attrs<{
  $playerEnabled: boolean;
}>((props) => ({
  style: {
    marginBottom: props.$playerEnabled ? `${PLAYER_HEIGHT}px` : "0",
  },
}))``;

type FilePlayerRecord = Pick<
  AssetSoundFile,
  "id" | "name" | "originalUrl" | "variantPreviewFetchUrl" | "type"
>;

function PlayerCell(
  record: FilePlayerRecord,
  onClick: (url: FilePlayerRecord) => void,
  playingRecord?: FilePlayerRecord,
) {
  if (record.variantPreviewFetchUrl && record.type.startsWith("audio/")) {
    return (
      <Button
        minimal
        icon="play"
        text="Play"
        onClick={() => onClick(record)}
        small
        active={playingRecord?.id === record.id}
      />
    );
  }
}

function DownloadCell(record: Pick<AssetSoundFile, "originalUrl">) {
  if (record.originalUrl) {
    return (
      <AnchorButton
        minimal
        icon="download"
        href={record.originalUrl}
        text="Download"
        small
      />
    );
  }
}

export default function SoundFileIndex() {
  const [playingRecord, setPlayingRecord] = useState<
    FilePlayerRecord | undefined
  >(undefined);

  return (
    <TableContainer $playerEnabled={!!playingRecord}>
      <RecordTable
        createUrl="/admin/files/upload"
        createText="Upload"
        deleteMutation={AdminDeleteFileDocument}
        query={AdminReadFilesDocument}
        dataKey="assetSoundFiles"
        recordNameKey="name"
        model="AssetSoundFile"
        columns={[
          { name: "id" },
          { name: "name" },
          { name: "size", renderer: FileSizeCell },
          { name: "channels" },
          { name: "duration" },
          { name: "type" },
          { name: "status" },
          { name: "insertedAt", renderer: DateTimeCell },
          {
            name: "play",
            renderer: (record) =>
              PlayerCell(
                record,
                (record) => setPlayingRecord(record),
                playingRecord,
              ),
          },
          {
            name: "download",
            renderer: DownloadCell,
          },
        ]}
      />
      <PlayerContainer $playerEnabled={!!playingRecord}>
        {playingRecord && (
          <>
            <PlayerHeader>
              <PlayerTitle>{playingRecord.name}</PlayerTitle>
              <PlayerActions>
                <Button
                  icon="cross"
                  minimal
                  onClick={() => setPlayingRecord(undefined)}
                />
              </PlayerActions>
            </PlayerHeader>
            <PlayerSource
              controls
              autoPlay
              key={playingRecord.variantPreviewFetchUrl!}
            >
              <source src={playingRecord.variantPreviewFetchUrl!} />
            </PlayerSource>
          </>
        )}
      </PlayerContainer>
    </TableContainer>
  );
}
