import { useQuery } from "@apollo/client";
import { ItemRenderer, Select } from "@blueprintjs/select";
import { Button, MenuItem } from "@blueprintjs/core";

import {
  AssetSoundFile,
  EditorReadFilesDocument,
} from "../../../__generated__/gql/graphql";

export type FileSelectOption = Pick<AssetSoundFile, "id" | "name">;

export type FileSelectProps = {
  selected: FileSelectOption | null;
  onSelect?: (file: FileSelectOption) => void;
};

function optionToLabel(option: FileSelectOption) {
  return `${option.name} (${option.id})`;
}

const renderItem: ItemRenderer<FileSelectOption> = (
  option,
  { handleClick, handleFocus },
) => {
  return (
    <MenuItem
      text={option.name}
      label={option.id}
      key={option.id}
      onClick={handleClick}
      onFocus={handleFocus}
      roleStructure="listoption"
    />
  );
};

export default function FileSelect({ selected, onSelect }: FileSelectProps) {
  const { data } = useQuery(EditorReadFilesDocument);

  if (!data) {
    return null; // FIXME read files once and use data from context
  }

  return (
    <Select<FileSelectOption>
      filterable={false}
      items={data.assetSoundFiles!}
      itemRenderer={renderItem}
      onItemSelect={(file) => {
        onSelect && onSelect(file);
      }}
    >
      <Button
        text={selected ? optionToLabel(selected) : "No file"}
        rightIcon="double-caret-vertical"
      />
    </Select>
  );
}
