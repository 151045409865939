import { styled } from "styled-components";

import { ChartAnnotation } from "../../../../../__generated__/gql/graphql";
import { useAreaLocalCoords } from "../../../hooks/useAreaLocalCoords";

import PositionMarker from "./PositionMarker";

const Container = styled.div.attrs<{
  $x: number;
  $y: number;
}>((props) => ({
  style: {
    left: `${props.$x}px`,
    top: `${props.$y}px`,
  },
}))`
  position: absolute;
  background: red;
`;

export type AnnotationMarkerProps = {
  annotation: ChartAnnotation;
};

export default function AnnotationMarker({
  annotation,
}: AnnotationMarkerProps) {
  const { x, y } = useAreaLocalCoords(
    annotation.origin.globalLongitude,
    annotation.origin.globalLatitude,
  );

  return (
    <Container
      $x={x}
      $y={y}
      className="AnnotationMarker"
      id={`AnnotationMarker-${annotation.id}`}
    >
      <PositionMarker />
      <div>{annotation.name}</div>
    </Container>
  );
}
