import { useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";
import { ChartBackground, ChartBackgroundTile } from "../../../../__generated__/gql/graphql";
import { useAttachmentUrl } from "../../hooks/useAttachmentUrl";
import { useMemo } from "react";

/**
 * This function is a workaround for https://github.com/undertoneaudio/monorepo/issues/7
 * 
 * In Chromium-based browsers if we load background tile image via <img> first, it will
 * be fetched without CORS and stored in the browser's cache without CORS headers. 
 * If we then try to re-fetch it in three.js, which requires CORS to be set, the browser 
 * will return cached version from <img> that has missing CORS headers and the request 
 * will fail.
 * 
 * The commonly recommended solution for this is to add any random parameter to the URL, 
 * so it appears to be a new URL to the browser, leading to bypassing cache. Unfortunately,
 * this technique will not work with URLs leading to S3 storage, as adding any parameter
 * to the URL will lead to signature mismatch.
 * 
 * However, the safe thing we can do is to reorder the query parameters. The signature
 * will be the same, but URL will appear to be different to the browser's cache.
 *  
 * @param url URL to reorder
 */
function reorderParams(url: string) {
  const parsedUrl = new URL(url);
  const entries: string[][] = [];

  for(const entry of parsedUrl.searchParams.entries()) {
    entries.push(entry);
  }

  entries.reverse();
  const newSearchParams = new URLSearchParams();
  for(const [name, value] of entries) {
    newSearchParams.append(name, value);
  }

  parsedUrl.search = newSearchParams.toString();
  return parsedUrl.toString();
}

type BackgroundTileMeshProps = {
  tile: ChartBackgroundTile;
}

function BackgroundTileMesh({ tile }: BackgroundTileMeshProps) {
  const url = useAttachmentUrl(tile, 'image')!;
  const noCacheUrl = useMemo(() => reorderParams(url), [url]);
  const texture = useLoader(TextureLoader, noCacheUrl);

  return (
    <mesh 
      position={[tile.localX + tile.localWidth / 2.0, 0, tile.localY + tile.localHeight / 2.0]} 
      rotation-x={Math.PI / -2}
    >
      <planeGeometry args={[tile.localWidth, tile.localHeight]}  />
      <meshBasicMaterial attach="material" map={texture} />
    </mesh>
  );
}

export type BackgroundMeshProps = {
  background: ChartBackground;
}

export default function BackgroundMesh({ background }: BackgroundMeshProps) {
  return (
    <>
      {background.tiles.map((tile) => 
        <BackgroundTileMesh key={tile.id} tile={tile} />
      )}
    </>
  );
}