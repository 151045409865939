import { useMemo } from "react";
import { useArea } from "../contexts/AreaContext";

export function useAreaLocalCoords(
  globalLongitude: number,
  globalLatitude: number,
) {
  const { area, sm } = useArea();

  const localCoords = useMemo(() => {
    const px = sm.px([globalLongitude, globalLatitude], area.backgroundZoom);

    return {
      x: px[0] - area.localOffsetX,
      y: px[1] - area.localOffsetY,
    };
  }, [sm, area.backgroundZoom, area.localOffsetX, area.localOffsetY, globalLongitude, globalLatitude]);

  return localCoords;
}
