import { Button, Dialog, DialogBody, DialogFooter } from "@blueprintjs/core";
import {
  SelectionSubject,
  SelectionType,
} from "../../../contexts/SelectionContext";
import { useCallback, useState } from "react";
import { useArea } from "../../../contexts/AreaContext";
import { useMutation } from "@apollo/client";
import { EditorUpdateGroupColorDocument } from "../../../../../__generated__/gql/graphql";
import { HexColorPicker } from "react-colorful";

function useSubjectGroup(subject: SelectionSubject) {
  const { area } = useArea();

  if (subject.selectionType === SelectionType.ChartSoundGroup) {
    const group = area.groups.find((g) => g.id === subject.selectionId);
    if (!group) throw new Error(`Group ${subject.selectionId} not found`);
    return group;
  }

  throw new Error(`Invalid selection type ${subject.selectionType}`);
}

type InstructionProps = {
  subject: SelectionSubject;
};

function Instruction({ subject }: InstructionProps) {
  const group = useSubjectGroup(subject);

  return (
    <div>
      Select color for group <b>{group.name}</b>:
    </div>
  );
}

export type ColorDialogProps = {
  isOpen: boolean;
  onClose: (created: boolean) => void;
  subject: SelectionSubject;
};

export function ColorDialog({ isOpen, onClose, subject }: ColorDialogProps) {
  const group = useSubjectGroup(subject);
  const [color, setColor] = useState(group.color);

  const [updateGroup, { loading }] = useMutation(
    EditorUpdateGroupColorDocument,
  );

  const handleCancel = useCallback(() => {
    onClose(false);
  }, [onClose]);

  const handleConfirm = useCallback(async () => {
    if (subject.selectionType === SelectionType.ChartSoundGroup) {
      await updateGroup({
        variables: {
          id: subject.selectionId,
          color,
        },
      });
    }

    onClose(true);
  }, [updateGroup, onClose, subject, color]);

  return (
    <Dialog
      title="Select color"
      isOpen={isOpen}
      onClose={handleCancel}
      canOutsideClickClose={false}
      canEscapeKeyClose={true}
    >
      <DialogBody>
        <Instruction subject={subject} />
        <HexColorPicker color={color} onChange={setColor} />
      </DialogBody>
      <DialogFooter
        actions={
          <>
            <Button text="Cancel" onClick={handleCancel} />
            <Button
              loading={loading}
              intent="primary"
              text="Select color"
              onClick={handleConfirm}
            />
          </>
        }
      />
    </Dialog>
  );
}
