import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import {
  ApolloProvider,
} from "@apollo/client";
import { BlueprintProvider } from "@blueprintjs/core";

import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

import router from "./init/router";
import { createApolloClient } from "./init/apollo";
import {
  Auth0Provider,
  useAuth0,
  withAuthenticationRequired,
} from "@auth0/auth0-react";
import { CanvasKit } from "canvaskit-wasm";
import { UserSocketProvider } from "./context/UserSocketContext";

declare global {
  interface Window {
    canvasKit: {
      instance: CanvasKit;
      loading: boolean;
      error: any;
    };
    initializeCanvasKit: () => void;
  }
}

// Check for env vars
[
  "REACT_APP_AUTH0_DOMAIN",
  "REACT_APP_AUTH0_CLIENT_ID",
  "REACT_APP_UNDERTONE_API_URL",
  "REACT_APP_MAPBOX_TOKEN",
].forEach((key) => {
  if (!process.env[key]) {
    throw new Error(`Environment variable ${key} is missing`);
  }
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

function App() {
  const { getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState<string | undefined>();
  const apolloClient = useMemo(() => accessToken ? createApolloClient(accessToken) : undefined, [accessToken]);

  useEffect(() => {
    getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_UNDERTONE_API_URL!,
      },
    }).then((accessToken) => {
      setAccessToken(accessToken);
      window.initializeCanvasKit();
    });
  }, [setAccessToken, getAccessTokenSilently]);

  if (!accessToken) return null;
  if (!apolloClient) return null;

  return (
    <ApolloProvider client={apolloClient}>
      <UserSocketProvider accessToken={accessToken}>
        <RouterProvider router={router} />
      </UserSocketProvider>
    </ApolloProvider>
  );
}

const AppProtected = withAuthenticationRequired(App);

root.render(
  <React.StrictMode>
    <BlueprintProvider>
      <Auth0Provider
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
        domain={process.env.REACT_APP_AUTH0_DOMAIN!}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: process.env.REACT_APP_UNDERTONE_API_URL!,
        }}
      >
        <AppProtected />
      </Auth0Provider>
    </BlueprintProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
