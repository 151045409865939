import { HTMLTable } from "@blueprintjs/core";
import { ChartSoundSource } from "../../../../../__generated__/gql/graphql";
import { useArea } from "../../../contexts/AreaContext";
import PanelContainer from "../PanelContainer";
import { styled } from "styled-components";
import {
  SelectionType,
  useSelection,
} from "../../../contexts/SelectionContext";

const SoundSourceRow = styled.tr<{ $selected: boolean }>`
  font-weight: ${(props) => (props.$selected ? "bold" : "normal")};
`;

type SoundSourceItemProps = {
  soundSource: ChartSoundSource;
};

function SoundSourceItem({ soundSource }: SoundSourceItemProps) {
  const { selectionType, selectionId, setSelection } = useSelection();

  const handleClick = () => {
    setSelection(SelectionType.ChartSoundSource, soundSource.id);
  };

  const selected =
    selectionType === SelectionType.ChartSoundSource &&
    selectionId === soundSource.id;

  return (
    <SoundSourceRow $selected={selected} onClick={handleClick}>
      <td>{soundSource.name}</td>
      <td>{soundSource.ambient && "Ambient"}</td>
    </SoundSourceRow>
  );
}

const HTMLTableWide = styled(HTMLTable)`
  width: 100%;
`;

export default function SoundSourcesPanel() {
  const { area } = useArea();

  return (
    <PanelContainer title="Sound Sources">
      <HTMLTableWide compact interactive>
        <tbody>
          {area.sources.map((soundSource) => (
            <SoundSourceItem key={soundSource.id} soundSource={soundSource} />
          ))}
        </tbody>
      </HTMLTableWide>
    </PanelContainer>
  );
}
