import styled from "styled-components";
import { ChartSoundSource } from "../../../../../../__generated__/gql/graphql";
import { FadeSliders } from "./FadeSliders";

import { FadePresets } from "./FadePresets";

const Container = styled.div`
  margin-bottom: 24px;
  margin-top: 24px;
`;

export type FadeEditorProps = {
  source: ChartSoundSource;
};

export function FadeEditor({ source }: FadeEditorProps) {
  return (
    <Container>
      <FadeSliders source={source} />
      <FadePresets source={source} />
    </Container>
  );
}
