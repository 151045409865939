import { CanvasKit, Canvas } from "canvaskit-wasm";
import { BakeGlobalParams, BakeMode } from "../types";

export type BakeRadial2DProps = {
  radius: number;
};

const STROKE_WIDTH = 1;
const ACCURACY = 0.25; // 1px = 25cm

function hexToRgb(hex: string): number[] {
  // Remove the leading hash symbol if present
  hex = hex.replace(/^#/, "");

  // Parse the hexadecimal string to extract the RGB components
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  // Return the RGB components as an array of numbers
  return [r, g, b];
}

function resolveFade(
  canvasKit: CanvasKit,
  colorsRgb: number[],
  fadeValues: number[],
) {
  const colors = fadeValues.map((value) =>
    canvasKit.Color(colorsRgb[0], colorsRgb[1], colorsRgb[2], value / 100.0),
  );
  const positionStep = 1.0 / (fadeValues.length - 1);
  const positions = fadeValues.map((_, i) => i * positionStep);

  // Final position
  positions[positions.length - 1] = 0.99;
  positions.push(1.0);

  colors.push(canvasKit.Color(colorsRgb[0], colorsRgb[1], colorsRgb[2], 0.0));

  return { colors, positions };
}

export function bakeRadial2D(
  { pixelToMetricRatio }: BakeGlobalParams,
  { radius }: BakeRadial2DProps,
  color: string,
  fadeValues: number[],
) {
  const renderWidth = (radius * 2.0) / ACCURACY;
  const renderHeight = (radius * 2.0) / ACCURACY;
  const displayWidth = radius * 2.0 * pixelToMetricRatio;
  const displayHeight = radius * 2.0 * pixelToMetricRatio;

  const drawCallback = (
    canvasKit: CanvasKit,
    canvas: Canvas,
    mode: BakeMode,
  ) => {
    const displayRadius = radius * pixelToMetricRatio;

    // Gradient
    {
      const paint = new canvasKit.Paint();
      let colors: Float32Array[] = [];
      let positions: number[] = [];

      switch (mode) {
        case BakeMode.EditorRegular: {
          const { colors: resolvedColors, positions: resolvedPositions } =
            resolveFade(canvasKit, hexToRgb(color), fadeValues);
          colors = resolvedColors;
          positions = resolvedPositions;
          break;
        }

        case BakeMode.EditorHover: {
          const { colors: resolvedColors, positions: resolvedPositions } =
            resolveFade(canvasKit, [192, 192, 0], fadeValues);
          colors = resolvedColors;
          positions = resolvedPositions;
          break;
        }

        case BakeMode.EditorSelected: {
          const { colors: resolvedColors, positions: resolvedPositions } =
            resolveFade(canvasKit, [255, 0, 0], fadeValues);
          colors = resolvedColors;
          positions = resolvedPositions;
          break;
        }
      }

      let shader = canvasKit.Shader.MakeRadialGradient(
        [displayRadius, displayRadius],
        displayRadius,
        colors,
        positions,
        canvasKit.TileMode.Clamp,
      );
      paint.setShader(shader);

      const rect = canvasKit.LTRBRect(0, 0, renderWidth, renderHeight);
      canvas.drawRect(rect, paint);
    }

    // Boundary
    if (mode === BakeMode.EditorSelected || mode === BakeMode.EditorHover) {
      const paint = new canvasKit.Paint();
      paint.setStyle(canvasKit.PaintStyle.Stroke);
      paint.setStrokeWidth(STROKE_WIDTH);
      paint.setAntiAlias(true);
      paint.setColor(
        canvasKit.Color(0, 0, 0, mode === BakeMode.EditorSelected ? 0.5 : 0.2),
      );
      canvas.drawCircle(
        displayRadius,
        displayRadius,
        displayRadius - STROKE_WIDTH / 2.0,
        paint,
      );
    }
  };

  return {
    renderWidth,
    renderHeight,
    displayWidth,
    displayHeight,
    drawCallback,
  };
}
