import styled from "styled-components";

const Container = styled.span`
  color: #00cc00;
`;


export type OnlineIndicatorProps = {
  online: boolean;
}

export default function OnlineIndicator({ online }: OnlineIndicatorProps) {
  if(!online) return null;
  return <Container>●</Container>;
}