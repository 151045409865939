import { styled } from "styled-components";
import { TOOLBOX_SIDEBAR_WIDTH } from "../toolbox/ToolboxSidebar";
import { DETAILS_SIDEBAR_WIDTH } from "../details/DetailsSidebar";
import { Space } from "react-zoomable-ui";
import { useArea } from "../../contexts/AreaContext";
import AnnotationOverlay from "./overlays/AnnotationOverlay";
import SoundSourceOverlay from "./overlays/SoundSourceOverlay";
import CloseButton from "./controls/CloseButton";
// import LoadingIndicator from "./controls/LoadingIndicator";
import BackgroundSelector from "./controls/BackgroundSelector";
import { useMutation } from "@apollo/client";
import {
  ChartBackgroundTile,
  EditorUpdateAreaEditorLocationDocument,
} from "../../../../__generated__/gql/graphql";
import FakeLocationMarker from "./overlays/FakeLocationMarker";
import { useAttachmentUrl } from "../../hooks/useAttachmentUrl";
import { TREE_SIDEBAR_WIDTH } from "../details/TreeSidebar";

const Container = styled.div`
  position: absolute;
  left: ${TOOLBOX_SIDEBAR_WIDTH}px;
  right: ${DETAILS_SIDEBAR_WIDTH + TREE_SIDEBAR_WIDTH}px;
  top: 0;
  bottom: 0;
  overflow: hidden;
  background: #fff;
  user-select: none;
`;

const Background = styled.div<{
  $width: number;
  $height: number;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
  position: absolute;
`;

const INITIAL_PADDING = 24;

type BackgroundTileImageProps = {
  tile: ChartBackgroundTile;
};

function BackgroundTileImage({ tile }: BackgroundTileImageProps) {
  const url = useAttachmentUrl(tile, "image")!;

  return (
    <img
      src={url}
      alt=""
      style={{
        width: `${tile.localWidth}px`,
        height: `${tile.localHeight}px`,
        left: `${tile.localX}px`,
        top: `${tile.localY}px`,
        position: "absolute",
      }}
    />
  );
}

export default function Preview2DContainer() {
  const { area, background, sm } = useArea();
  const [updateEditorLocation] = useMutation(
    EditorUpdateAreaEditorLocationDocument,
  );

  return (
    <Container>
      <Space
        onCreate={(viewPort) => {
          viewPort.camera.centerFitAreaIntoView({
            left: -INITIAL_PADDING,
            top: -INITIAL_PADDING,
            width: area.localWidth + INITIAL_PADDING * 2,
            height: area.localHeight + INITIAL_PADDING * 2,
          });
        }}
        onContextMenu={(_, coords) => {
          if (
            coords.x >= 0 &&
            coords.x <= area.localWidth &&
            coords.y >= 0 &&
            coords.y <= area.localHeight
          ) {
            const ll = sm.ll(
              [area.localOffsetX + coords.x, area.localOffsetY + coords.y],
              area.backgroundZoom,
            );

            updateEditorLocation({
              variables: { id: area.id, longitude: ll[0], latitude: ll[1] },
            });
          }
        }}
      >
        <Background $width={area.localWidth} $height={area.localHeight}>
          {background.tiles.map((tile) => (
            <BackgroundTileImage key={tile.id} tile={tile} />
          ))}

          <AnnotationOverlay />
          <SoundSourceOverlay />
          {area.globalFakeGpsLongitude && area.globalFakeGpsLatitude && (
            <FakeLocationMarker
              longitude={area.globalFakeGpsLongitude}
              latitude={area.globalFakeGpsLatitude}
            />
          )}
        </Background>
      </Space>

      <>
        {/* <LoadingIndicator /> */}
        <CloseButton />
        <BackgroundSelector />
      </>
    </Container>
  );
}
