import { ReactNode, createContext, useContext, useState } from "react";

export enum Tool {
  Select = 1,
  Drag,
}

export type ToolState = {
  tool: Tool;
  setTool: (newTool: Tool) => void;
};

const ToolContext = createContext<ToolState>({
  tool: Tool.Select,
  setTool: () => {},
});

export function useTool() {
  return useContext(ToolContext);
}

export type ToolProviderProps = {
  children: ReactNode;
};

export function ToolProvider({ children }: ToolProviderProps) {
  const [tool, setTool] = useState(Tool.Select);

  const value = {
    tool,
    setTool,
  };

  return <ToolContext.Provider value={value}>{children}</ToolContext.Provider>;
}
