import styled from "styled-components";
import { Button, Dialog, DialogBody, DialogFooter } from "@blueprintjs/core";
import { ContentTree } from "./ContentTree";
import {
  useSelectionState,
  SelectionSubject,
  SelectionType,
} from "../../../contexts/SelectionContext";
import { useCallback } from "react";
import { useArea } from "../../../contexts/AreaContext";
import { useMutation } from "@apollo/client";
import {
  EditorUpdateGroupParentDocument,
  EditorUpdateSourceGroupDocument,
} from "../../../../../__generated__/gql/graphql";

type InstructionProps = {
  subject: SelectionSubject;
};

function Instruction({ subject }: InstructionProps) {
  const { area } = useArea();

  if (subject.selectionType === SelectionType.ChartSoundGroup) {
    const group = area.groups.find((g) => g.id === subject.selectionId);
    if (!group) throw new Error(`Group ${subject.selectionId} not found`);

    return (
      <div>
        Select destination for moving group <b>{group.name}</b>:
      </div>
    );
  }

  if (subject.selectionType === SelectionType.ChartSoundSource) {
    const source = area.sources.find((g) => g.id === subject.selectionId);
    if (!source) throw new Error(`Source ${subject.selectionId} not found`);

    return (
      <div>
        Select destination for moving source <b>{source.name}</b>:
      </div>
    );
  }

  throw new Error(`Invalid selection type ${subject.selectionType}`);
}

const ContentTreeContainer = styled.div`
  background: #fff;
  margin-top: 16px;
  margin-bottom: 8px;
  border: 1px solid rgba(17, 20, 24, 0.15);
`;

export type MoveDialogProps = {
  isOpen: boolean;
  onClose: (created: boolean) => void;
  subject: SelectionSubject;
};

export function MoveDialog({ isOpen, onClose, subject }: MoveDialogProps) {
  const { selectionType, selectionId, setSelection } = useSelectionState();

  const [updateSource, { loading: loadingSource }] = useMutation(
    EditorUpdateSourceGroupDocument,
  );
  const [updateGroup, { loading: loadingGroup }] = useMutation(
    EditorUpdateGroupParentDocument,
  );

  const handleCancel = useCallback(() => {
    onClose(false);
  }, [onClose]);

  const handleConfirm = useCallback(async () => {
    if (subject.selectionType === SelectionType.ChartSoundGroup) {
      await updateGroup({
        variables: {
          id: subject.selectionId,
          parentId: selectionId,
        },
      });
    } else if (subject.selectionType === SelectionType.ChartSoundSource) {
      await updateSource({
        variables: {
          id: subject.selectionId,
          groupId: selectionId,
        },
      });
    }

    onClose(true);
  }, [updateGroup, updateSource, onClose, subject, selectionId]);

  return (
    <Dialog
      title="Move"
      isOpen={isOpen}
      onClose={handleCancel}
      canOutsideClickClose={false}
      canEscapeKeyClose={true}
    >
      <DialogBody>
        <Instruction subject={subject} />
        <ContentTreeContainer>
          <ContentTree
            selectionId={selectionId}
            selectionType={selectionType}
            onSelectionChange={setSelection}
            enableSources={false}
            editable={false}
            disabledGroupIds={
              subject.selectionType === SelectionType.ChartSoundGroup
                ? [subject.selectionId]
                : []
            }
          />
        </ContentTreeContainer>
      </DialogBody>
      <DialogFooter
        actions={
          <>
            <Button text="Cancel" onClick={handleCancel} />
            <Button
              loading={loadingSource || loadingGroup}
              intent="primary"
              text="Move"
              onClick={handleConfirm}
            />
          </>
        }
      />
    </Dialog>
  );
}
