import { useLoader, useThree } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";
import { AudioListener, AudioLoader, PositionalAudio } from "three";
import { AssetSoundFileStatusEnum, ChartSoundSource } from "../../../../__generated__/gql/graphql"
import { useArea } from "../../contexts/AreaContext";
import { useAreaLocalCoords } from "../../hooks/useAreaLocalCoords";
import { useAttachmentUrl } from "../../hooks/useAttachmentUrl";

// Workaround for issues with PositionalAudio, see https://github.com/pmndrs/drei/issues/504
type SoundProps = {
  source: ChartSoundSource;
}

function Sound({ source }: SoundProps) {
  const { area } = useArea();

  const url = useAttachmentUrl(source.file!, 'variantPreview');

  const sound = useRef<PositionalAudio>();
  const { camera } = useThree();
  const [listener] = useState(() => new AudioListener());
  const buffer = useLoader(AudioLoader, url!);

  ///@ts-ignore
  useEffect(() => {
    if(!sound.current || !buffer) return;
    sound.current.setBuffer(buffer);
    sound.current.setRefDistance(1);
    sound.current.setDistanceModel('linear');
    sound.current.setMaxDistance(source.shape.radius * area.localToRealRatio);
    sound.current.setLoop(true);
    sound.current.play();
    camera.add(listener);
    return () => {
      camera.remove(listener);
    }
  }, [area.localToRealRatio, buffer, camera, listener, source.shape.radius]);

  ///@ts-ignore
  return <positionalAudio ref={sound} args={[listener]} />;
}

export type SoundSourceMeshProps = {
  source: ChartSoundSource;
}

export default function SoundSourceMesh({ source }: SoundSourceMeshProps) {
  const { area } = useArea();

  // FIXME move origin 
  const { x, y } = useAreaLocalCoords(
    source.origin.globalLongitude,
    source.origin.globalLatitude,
  );
  const radiusOffset = source.shape.radius * area.localToRealRatio;

  const hasFile = source.file && source.file.status === AssetSoundFileStatusEnum.Ready;

  return (
    <>
      <mesh position={[x + radiusOffset, 0, y + radiusOffset]}>
        {hasFile && <Sound source={source} />}
        <sphereGeometry args={[1]} />
        <meshBasicMaterial color={'red'} />
      </mesh>
      <mesh position={[x + radiusOffset, 0, y + radiusOffset]}>
        <sphereGeometry args={[source.shape.radius * area.localToRealRatio]} />
        <meshBasicMaterial wireframe color={'red'} />
      </mesh>
    </>
  )
}