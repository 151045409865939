import { Button, Dialog, DialogBody, DialogFooter } from "@blueprintjs/core";
import { DocumentNode } from "graphql";
import { useCallback } from "react";
import EditForm from "./EditForm";
import { useEditForm } from "./useEditForm";

export type CreateDialogProps = {
  mutation: DocumentNode;
  isOpen: boolean;
  onClose: (created: boolean) => void;
  model: string;
};

export default function CreateDialog({
  mutation,
  isOpen,
  onClose,
  model,
}: CreateDialogProps) {
  const formSchema = useEditForm(mutation);
  const { submit, invalid, loading } = formSchema;

  const handleClick = useCallback(async () => {
    const success = await submit();
    if (success) onClose(true);
  }, [submit, onClose]);

  const handleCancel = () => {
    onClose(false);
  };

  return (
    <Dialog
      title={`Create ${model}`}
      isOpen={isOpen}
      onClose={handleCancel}
      canOutsideClickClose={false}
      canEscapeKeyClose={true}
    >
      <DialogBody>
        <EditForm formSchema={formSchema} />
      </DialogBody>
      <DialogFooter
        actions={
          <>
            <Button text="Cancel" onClick={handleCancel} />
            <Button
              loading={loading}
              intent="primary"
              text="Create"
              onClick={handleClick}
              disabled={invalid}
            />
          </>
        }
      />
    </Dialog>
  );
}
