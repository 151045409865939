import { DocumentNode, Kind, NamedTypeNode, NonNullTypeNode } from "graphql";
import { useCallback, useMemo, useState } from "react";
import { OperationDefinitionNode } from "graphql";
import { ApolloError, useMutation } from "@apollo/client";
import { RecordRow } from ".";

const isInvalid = (
  type: string,
  required: boolean,
  value?: EditFormFieldValue,
) => {
  if (required) {
    if (value === undefined) {
      return true;
    }

    if (type === "String" && value.casted === "") {
      return true;
    }
  }
  return false;
};

/**
 * Structure to keep the form field value while it is being changed.
 *
 * Casted contains value of type that is required by the given field.
 *
 * Internal contains any value that is required internally by the
 * input component to operate. For example in BlueprintJS's
 * NumericInput operating in controlled mode, we need to store value
 * as string as well so user can type for example '0.' which is not
 * a valid numeric value by itself (the dot will be stripped).
 */
export type EditFormFieldValue = {
  casted: any;
  internal?: any;
};

export type EditFormFieldDefinition = {
  name: string;
  type: string;
  required: boolean;
  value?: EditFormFieldValue;
  invalid: boolean;
  invalidReason?: string[];
};

export type EditFormSchema = ReturnType<typeof useEditForm>;

export function useEditForm(mutation: DocumentNode, record?: RecordRow) {
  const [mutate, { loading }] = useMutation(mutation);

  // Extract variable definitions from mutation
  const initialFormFields = useMemo(() => {
    const definition = mutation.definitions[0];
    if (!definition) {
      throw new Error(`Mutation passed to EditForm has no definition`);
    }
    if (definition.kind !== "OperationDefinition") {
      throw new Error(
        `Mutation passed to EditForm has invalid kind of ${definition.kind}`,
      );
    }
    const operation = definition as OperationDefinitionNode;
    if (!operation.variableDefinitions) {
      throw new Error(`Mutation passed to EditForm has no variableDefinitions`);
    }

    const formFields: EditFormFieldDefinition[] = [];
    for (const variableDefinition of operation.variableDefinitions) {
      const name = variableDefinition.variable.name.value;

      // TODO No support for list types
      const type = (
        variableDefinition.type.kind === Kind.NON_NULL_TYPE
          ? ((variableDefinition.type as NonNullTypeNode).type as NamedTypeNode)
          : (variableDefinition.type as NamedTypeNode)
      ).name.value;

      const required = variableDefinition.type.kind === Kind.NON_NULL_TYPE;
      const value: EditFormFieldValue | undefined =
        record && record.hasOwnProperty(name)
          ? { casted: record[name] }
          : undefined;
      const invalid = isInvalid(type, required, value);

      formFields.push({ name, type, required, invalid, value });
    }

    return formFields;
  }, [mutation, record]);

  // Handle form values
  const [formFields, setFormFields] =
    useState<EditFormFieldDefinition[]>(initialFormFields);

  const setFormValue = useCallback(
    (name: string, value: EditFormFieldValue) => {
      const formField = formFields.find((f) => f.name === name)!;
      const invalid = isInvalid(formField.type, formField.required, value);

      formField.invalid = invalid;
      formField.value = value;

      // Not the most efficient way of notyfying that some value has changed
      // but good enough for now. Other approaches would have required deep
      // coparison of formFields in all uses of formFields.
      setFormFields(structuredClone(formFields));
    },
    [setFormFields, formFields],
  );

  const submit = useCallback(async () => {
    const variables: { [name: string]: any } = {};
    for (const { name, value } of formFields) {
      if (value) {
        variables[name] = value.casted;
      }
    }

    try {
      await mutate({ variables });
      return true;
    } catch (e) {
      if (e instanceof ApolloError) {
        const validationErrors = (e as ApolloError).graphQLErrors[0]?.extensions
          ?.validation as { [name: string]: string[] };

        if (validationErrors) {
          const newFormFields = formFields.map((formField) => {
            if (validationErrors.hasOwnProperty(formField.name)) {
              return {
                ...formField,
                invalid: true,
                invalidReason: validationErrors[formField.name],
              };
            } else {
              return {
                ...formField,
                invalid: false,
                invalidReason: undefined,
              };
            }
          });
          setFormFields(structuredClone(newFormFields));
        } else {
          const newFormFields = formFields.map((formField) => {
            return {
              ...formField,
              invalid: false,
              invalidReason: undefined,
            };
          });
          setFormFields(structuredClone(newFormFields));
        }
      }
      return false;
    }
  }, [formFields, mutate]);

  const invalid = useMemo(
    () => formFields.some((f) => f.invalid),
    [formFields],
  );

  return { submit, loading, invalid, formFields, setFormValue };
}
